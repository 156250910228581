// React
import React from "react";
// UI
import { Box, Type } from "../../../ui";

const StatusRenderGrid = (row) => {
  // Active or Not
  var status;
  switch (row.isActive) {
    case true:
      status = "Active";
      break;
    default:
      status = "Not Active";
      break;
  }
  return (
    <Box sx={{ h: 30, p: 5 }}>
      <Type
        sx={{
          c: row?.isActive ? "green900" : "pink500",
          fs: 14,
        }}
      >
        {status}
      </Type>
    </Box>
  );
};

export default StatusRenderGrid;
