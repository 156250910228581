import styled, { css } from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

const Type = styled.p.attrs((p) => {
  p.var = p.var ? p.var : "p";
})`
  color: ${(p) => p.theme.color.black};
  ${(p) =>
    p.var === "p" &&
    css`
      font-size: 16px;
      line-height: 23px;
      margin: 0;
      color: ${(p) => p.theme.color.black};
      font-family: ${(p) => p.theme.fonts.body};
    `}
  ${(p) =>
    p.var === "display" &&
    css`
      font-size: 38px;
      line-height: 42px;
      font-weight: 900;
      margin: 0;
      font-family: ${(p) => p.theme.fonts.display};
      text-transform: uppercase;
      color: ${(p) => p.theme.color.secondary};
      @media only screen and (max-width: 750px) {
        font-size: 36px;
        line-height: 40px;
      }
    `}
  ${(p) =>
    p.var === "h1" &&
    css`
      font-size: 32px;
      line-height: 42px;
      margin: 0;
      font-weight: 700;
      font-family: ${(p) => p.theme.fonts.primary};
      text-transform: uppercase;
      color: ${(p) => p.theme.color.secondary};
    `}
    ${(p) =>
    p.var === "h2" &&
    css`
      font-size: 32px;
      line-height: 42px;
      margin: 0;
      font-weight: 600;
      font-family: ${(p) => p.theme.fonts.primary};
      color: ${(p) => p.theme.color.secondary};
    `}
    ${(p) =>
    p.var === "h3" &&
    css`
      font-size: 26px;
      line-height: 35px;
      margin: 0;
      font-weight: 600;
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "h4" &&
    css`
      font-size: 22px;
      line-height: 32px;
      margin: 0;
      font-weight: 700;
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "h5" &&
    css`
      font-size: 18px;
      line-height: 28px;
      margin: 0;
      font-weight: 600;
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "h6" &&
    css`
      font-size: 16px;
      line-height: 25px;
      margin: 0;
      font-weight: 600;
      font-family: ${(p) => p.theme.fonts.primary};
    `}
  ${(p) =>
    p.var === "supTitle" &&
    css`
      font-size: 22px;
      line-height: 30px;
      margin: 0;
      font-weight: 700;
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "subTitle" &&
    css`
      font-size: 20px;
      line-height: 24px;
      margin: 0;
      font-weight: 600;
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "Title" &&
    css`
      font-size: 28px;
      line-height: 24px;
      margin: 0;
      font-weight: bold;
      color: ${(p) => p.theme.color.heading};
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "Heading" &&
    css`
      font-size: 20px;
      line-height: 24px;
      margin: 0;
      font-weight: bold;
      color: ${(p) => p.theme.color.heading};
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "tableTitle" &&
    css`
      font-size: 15px;
      line-height: 19px;
      margin: 0;
      color: ${(p) => p.theme.color.heading};
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "Label" &&
    css`
      font-size: 15px;
      line-height: 25px;
      margin: 0;
      color: ${(p) => p.theme.color.bodyLight};
      font-family: ${(p) => p.theme.fonts.primary};
    `}
    ${(p) =>
    p.var === "titleWisp" &&
    css`
      font-size: 18px;
      line-height: 25px;
      margin: 0;
      font-weight: 400;
      text-transform: uppercase;
      font-family: ${(p) => p.theme.fonts.body};
    `}
    ${(p) =>
    p.var === "whiteText" &&
    css`
      font-size: 20px;
      line-height: 23px;
      margin: 0;
      font-weight: bold;
      color: ${(p) => p.theme.color.white};
      font-family: ${(p) => p.theme.fonts.body};
    `}
    
  ${stylesBlocks}
`;
export default Type;
