import { Route } from "react-router-dom";
import { RequireAuth } from "../../services/auth";
import Networks from "./Networks";
import NetworksList from "./NetworksList";

export default (
  <Route
    path="networks"
    exact
    element={
      <RequireAuth>
        <Networks />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <NetworksList />
        </RequireAuth>
      }
    />
  </Route>
);
