import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Flex, Icon, Link } from "../../ui";

const links = [
  { name: "Dashboard", path: "/dashboard", icon: "DashboardIcon" },
  { name: "Categories", path: "/categories", icon: "Category" },
  { name: "Companies", path: "/companies", icon: "Company" },
  { name: "Countries", path: "/countries", icon: "Country" },
  { name: "Clients", path: "/clients", icon: "Clients" },
  { name: "Users", path: "/users", icon: "User" },
  { name: "Networks", path: "/networks", icon: "Networks" },
  { name: "USDT", path: "/usdt", icon: "Dollar" },
];

const StyledNav = styled.nav`
  ul {
    list-style: none;
    padding: 0;
    margin: 30px 0;
    li {
      a {
        font-size: 16px;
        color: ${(props) => props.theme.color.black};
        text-decoration: none;
        padding: 12px 16px;
        font-weight: 500;
        font-size: 15px;
        border-left: ${(p) => p.theme.color.white} 3px solid;
      }
      &:hover {
        a {
          background-color: ${(p) => p.theme.color.gray400}22;
          border-left-color: ${(p) => p.theme.color.gray700};
        }
      }
      &.selected {
        a {
          background-color: ${(p) => p.theme.color.secondary}22;
          border-left-color: ${(p) => p.theme.color.secondary};
        }
      }
      @media only screen and (max-width: 1200px) {
        padding: 0 20px;
      }
    }
  }
  &.md ul {
    display: block;
    text-align: right;
    margin-top: 80px;
    margin-right: 14px;
    li {
      padding: 10px 0;
    }
  }
`;

function Nav(props) {
  const location = useLocation();
  return (
    <StyledNav {...props}>
      <ul>
        {links.map((link) => (
          <li
            key={link.name}
            className={
              location.pathname.startsWith(`${link.path}`)
                ? "selected"
                : undefined
            }
          >
            <Flex jc="flex-start" gap={10} as={Link} to={link.path}>
              <Icon name={link.icon} w={16} c="gray600" />{" "}
              <span>{link.name}</span>
            </Flex>
          </li>
        ))}
      </ul>
    </StyledNav>
  );
}

export default Nav;
