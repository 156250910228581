// React
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// Providers
import { useCategories } from "./Provider";
// UI
import { Box, Button, Flex, InputText, Type } from "../../ui";
// Fields
import fields from "./fields";
// YUP
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUtils } from "../../services/utilsProvider";
// Schema
const schema = Yup.object().shape({
  categoryName: Yup.string().required("Required"),
});

const CategoryForm = ({ setShow, category }) => {
  // Use Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Providers
  const { create, update } = useCategories();
  const { notify } = useUtils();
  // Use State
  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    try {
      if (category) await update(category?.id, values);
      else await create(values);
      setShow(false);
      if (category) notify("Category Updated Successfully");
      else notify("Category Added Successfully");
    } catch (error) {
      if (error.status === 400) {
        if (error.errors.response === 21) setError("Category Already Exists");
      }
    }
  };

  return (
    <>
      {/* Header */}
      <Flex jc="space-between" sx={{ mb: 20 }}>
        <Type as="h1" sx={{ fw: 600, fs: 28, mb: 20 }}>
          {category ? "Update Category " : "New Category"}
        </Type>
      </Flex>
      {error && (
        <span data-testid="err" sx={{ c: "primary", mb: 20 }}>
          {error}
        </span>
      )}
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="formBox"
      >
        {/* Form */}
        <Flex jc="center" ai="center" dir="column" sx={{ w: 400 }}>
          {fields?.map((f, index) => (
            <InputText
              label={f?.label}
              name={f?.name}
              type={f?.type}
              data-testid={f?.name}
              key={index}
              error={!!errors[f?.name]}
              msg={errors[f?.name]?.message}
              style={{ width: "100%" }}
              inputProps={{
                ...register(f?.name),
                "aria-label": f?.name,
                defaultValue: category && category[f?.name],
              }}
            />
          ))}
          <Button
            type="submit"
            bg="secondary"
            name="create"
            data-testid="create"
            sx={{ fs: "15px", w: 400, br: "8px" }}
          >
            {category ? "Update " : "Add Category"}
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default CategoryForm;
