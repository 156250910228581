import { useState } from "react";
import { Box, Grid } from "../ui";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import styled from "styled-components";

const SGrid = styled(Grid)`
  height: 100vh;
  transition: all 0.2s ease-in;
  nav {
    li {
      span:last-child {
        opacity: 0;
        transition: opacity 0.2s ease-out;
      }
    }
  }
  &.open {
    nav {
      li {
        span:last-child {
          transition: opacity 0.2s ease-in;
          opacity: 1;
        }
      }
    }
  }
`;

function Layout({ children }) {
  const [open, setOpen] = useState(true);

  return (
    <SGrid
      className={open ? "open" : ""}
      cols={open ? "220px 1fr" : "60px 1fr"}
      rows="max-content 1fr"
    >
      <Sidebar
        sx={{ gr: "1 / 3", border: ["gray200", 1, "solid"], of: "hidden" }}
      />
      <Header setOpen={setOpen} sx={{ border: ["gray200", 1, "solid"] }} />
      <Box as="main" sx={{ bgc: "gray50" }}>
        <Box sx={{mw: 1350, mx: 'auto', p: 30}}>{children}</Box>
      </Box>
    </SGrid>
  );
}

export default Layout;
