import React from "react";
import { Box, Card, CardGrid, Grid } from "../../ui";
import TextRender from "../Networks/CellRender/TextRender";
import NetworkCard from "./Styled/NetworkCard";

const NetworkChart = ({ liquidity }) => {
  const cols = [
    {
      label: "STITVEST WALLET",
      name: "stitvestWallet",
      gridViewOnLabel: true,
      copied: true,
    },

    {
      label: "WALLET EXPLORER",
      name: "walletOnExplorer",
      gridViewOnLabel: true,
      copied: true,
    },
    {
      label: "BALANCE",
      name: "balance",
      gridViewOnLabel: true,
    },
    {
      label: "CURRENCY",
      name: "currency",
      gridViewOnLabel: true,
    },
  ];

  return (
    <Box sx={{ mt: "100px" }}>
      <NetworkCard
        avatarName="networkIcon"
        headName="networkID"
        data={liquidity}
        headLabel="Network ID"
        empty={liquidity?.length === 0 ? true : false}
        cols={cols}
        gridCols={"1fr 1fr"}
        gap={50}
        title={true}
      />
    </Box>
  );
};

export default NetworkChart;
