import { useState } from "react";
import { useEffect } from "react";
import request from "../../services/request";

function useImageLink(hash, type = "image") {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if(!hash) return;
    (async () => {
      const response = await request(
        `upload/image/?key=${hash}`,
        null,
        {
          headers: {
            "Content-Type": type,
          },
        },
        true
      );
      const imageBlob = await response.blob();
      setUrl(URL.createObjectURL(imageBlob));
    })();
  }, [hash, type]);

  return url;
}

export default useImageLink;
