import { Box, Flex } from "../ui";

function LayoutMasked({ children }) {
  return (
    <Flex sx={{ bgc: "gray100", h: "100vh" }}>
      <Box sx={{ w: '100%', h: '100%', bgc: "white", border: ["gray200", 1, "solid", 0] }}>
        {children}
      </Box>
    </Flex>
  );
}

export default LayoutMasked;
