import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import Company from "./Company";
import CompanyList from "./CompanyList";
import CompanyDetail from "./CompanyDetail";
import UpdateStatus from "./UpdateStatus";
import ImageSlider from "./ImageSlider";
import Documents from "./Documents";

export default (
  <Route
    path="companies"
    exact
    element={
      <RequireAuth>
        <Company />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <CompanyList />
        </RequireAuth>
      }
    />
    <Route
      path="detail/:id"
      element={
        <RequireAuth>
          <CompanyDetail />
        </RequireAuth>
      }
    />
    <Route
      path="update-status/:id"
      element={
        <RequireAuth>
          <UpdateStatus />
        </RequireAuth>
      }
    />
    <Route
      path="image-slider/:id"
      element={
        <RequireAuth>
          <ImageSlider />
        </RequireAuth>
      }
    />
    <Route
      path="documents/:id"
      element={
        <RequireAuth>
          <Documents />
        </RequireAuth>
      }
    />
  </Route>
);
