import styled from "styled-components";
import { Box, Flex, Icon, Type } from "../../../ui";

const GrBackground = styled.div`
  background: ${(p) => p.bg};
  width: 263px;
  height: 150px;
  border-radius: 8px;
  display: grid;
  place-items: center;
  color: #fff;
  @media only screen and (max-width: 720px) {
    display: grid;
    grid-column-template: 1fr;
  }
`;
const Circle = styled.div`
  width: 46px;
  height: 46px;
  opacity: 0.15;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: 15px;
  left: -10px;
`;

function GradientCard({ background, count, title, IconName, IconW }) {
  return (
    <GrBackground bg={background}>
      {" "}
      <Flex>
        <div
          style={{
            position: "relative",
            marginRight: "10px",
          }}
        >
          <Circle />
          <Icon name={IconName} w={IconW} c="white" />
        </div>
        <Box>
          <Type var="whiteText" sx={{ m: "10px" }}>
            {count}{" "}
          </Type>
          <Type var="whiteText">{title}</Type>
        </Box>
      </Flex>
    </GrBackground>
  );
}

export default GradientCard;
