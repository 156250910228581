import React, { useEffect, useState } from "react";
import { useClient } from "./Provider";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Pagination,
  Select,
  TableContainer,
  Type,
} from "../../ui";
import CustomerName from "./render/CustomerName";
import CountryRender from "./render/CountryRender";
import mobileRender from "./render/mobileRender";
import verifiedRender from "./render/verifiedRender";
import { useNavigate } from "react-router-dom";
import Dialog from "../../ui/Dialog";
import AddWallet from "./render/AddWallet";
import ErrorsList from "../../ui/ErrorsList";

const ClientsList = () => {
  let navigate = useNavigate();
  const [isVerified, setisVerified] = useState(true);
  const [Show, setShow] = useState(false);
  const [id, setId] = useState(0);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  // const [reload, setReload] = useState(false);

  const {
    state: { list: clients, status },
    fetchList: fetchClients,
    createWallet: addWallet,
  } = useClient();

  useEffect(() => {
    fetchClients(isVerified);
    // }, [isVerified, reload]);
  }, [isVerified, fetchClients]);

  const cols = [
    { label: "CUSTOMER NAME", render: CustomerName },
    { label: "LOCATION", render: CountryRender },
    { label: "MOBILE", render: mobileRender },
    { label: "VERIFIED", name: "isVerified", render: verifiedRender },
  ];

  const handleAddWallet = (id, email) => {
    setId(id);
    setEmail(email);
    setShow(true);
    setError(null);
  };

  const handleAdd = async () => {
    try {
      setShow(false);
      await addWallet(id, email);
      // setReload(true);
    } catch (error) {
      console.log(error, "error");
      setError({ title: error.errors.message });
    }
  };
  return (
    <Box>
      <Type as="h1" var="p" sx={{ fw: 600, fs: 30, c: "#303D4D", mb: 20 }}>
        Clients <span style={{ color: "grey" }}>{`(${clients?.length})`}</span>
      </Type>
      <Flex jc="flex-start">
        <Flex c="space-between" sx={{ mr: 50 }}>
          <Type var="Label" sx={{ pr: 10, ta: "center", mt: 10 }}>
            Show
          </Type>
          <Select
            data={[10, 50, 100]}
            inputStyle={{ width: "95px" }}
            size="medium"
            name="10"
          />
        </Flex>
        <Checkbox
          id="isVerified"
          label="Verified Client"
          onChange={() => setisVerified(!isVerified)}
          checked={isVerified}
        />
      </Flex>
      <Box>
        <TableContainer
          empty={clients?.length === 0 ? true : false}
          data={clients}
          cols={cols}
          showButton={{
            label: "ACTION",
            render: (p) => navigate("detail/" + p.user.id),
            Icon: "Detail",
          }}
          showButtonAdd={{
            label: "WALLET",
            render: (row) => handleAddWallet(row.id, row.user.email),
            Icon: "AddButtonNormal",
            loading: status !== "idle" && status.slice(-2),
          }}
          // moreOptions={[
          //   {
          //     name: "Detail",
          //     render: (p) => (
          //       <Button
          //         onClick={() => navigate("detail/" + p.user.id)}
          //         variant="text"
          //         sx={{ c: "black" }}
          //       >
          //         Detail
          //       </Button>
          //     ),
          //   },
          //]}
          tableProps={{ borderCollapse: "seperate", borderSpacing: " 0 8px" }}
        />
      </Box>
      <Flex jc="end" ai="flex-top" sx={{ mb: 60 }}>
        <Box>
          <Pagination
            count={10}
            page={1}
            onPageChange={() => console.log("page")}
          />
        </Box>
      </Flex>

      <Dialog className="dialog" show={Show} setShow={setShow}>
        <Box sx={{ mb: 20, p: "10px" }}>
          {error && <ErrorsList error={error} />}
        </Box>
        <Flex sx={{ p: "10px", mb: "30px" }}>
          <Type var="tableTitle" sx={{ fs: 18 }}>
            {" "}
            Are you sure add wallet to{" "}
          </Type>
          <Type var="Label" sx={{ fs: 18, ml: "10px", mr: "5px" }}>
            {email}
          </Type>{" "}
          ?
        </Flex>
        <Flex jc="flex-end">
          <Button bg="secondary" onClick={handleAdd}>
            Add
          </Button>
          <Button
            bg="grayButton"
            sx={{ c: "black", ml: "20px" }}
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </Flex>
      </Dialog>
    </Box>
  );
};

export default ClientsList;
