import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRequest } from "./request";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  let [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  let [blockChainData, setBlockChainData] = useState();
  const req = useRequest();

  useEffect(() => {
    if (user) return;
    const localUser = localStorage.getItem("user");
    if (localUser) setUser(JSON.parse(localStorage.getItem("user")));
  }, [user]);

  let isLoggedIn = useCallback(() => {
    return user?.tokens;
  }, [user]);

  let addAccount = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`account/register/`, data, { method: "POST" });
          setUser({ ...res.data, id: res.data?.userID });
          localStorage.setItem(
            "user",
            JSON.stringify({ ...res.data, id: res.data?.userID })
          );
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let resetPass = useCallback(
    (email) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `auth/password/reset/`,
            { email },
            { method: "POST" }
          );
          localStorage.setItem("resetPassEmail", email);
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let changePass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const email = localStorage.getItem("resetPassEmail");
          const res = await req(
            `auth/password/reset/confirm/`,
            { ...data, email },
            { method: "POST" }
          );
          localStorage.removeItem("resetPassEmail");
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let changePass2 = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `auth/password/change/`,
            { ...data },
            { method: "POST" },
            true
          );
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let getCountries = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`country/`, data);
          resolve(res.data.countryList);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  const saveTokens = useCallback(
    (newTokens) => {
      const userTokens = { ...user, tokens: { ...user.tokens, ...newTokens } };
      window.localStorage.setItem("user", JSON.stringify(userTokens));
      setUser(userTokens);
    },
    [user]
  );

  let signin = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`auth/login`, data);
          if (
            res.data.user.roles.find(
              (role) => role === "Admin" || role === "CMS"
            )
          ) {
            window.localStorage.setItem(
              "user",
              JSON.stringify({ user: res.data.user, tokens: res.data })
            );
            localStorage.setItem(
              "blockchainData",
              JSON.stringify(res.data.blockchainData)
            );
            setBlockChainData(res.data.blockchainData);
            setUser({ user: res.data.user, tokens: res.data });
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let signout = useCallback((redirect = true) => {
    return new Promise((resolve) => {
      window.localStorage.removeItem("user");
      setUser(null);
      if (redirect) window.location.href = "/";
      resolve();
    });
  }, []);

  let value = {
    user,
    blockChainData,
    signin,
    signout,
    getCountries,
    addAccount,
    saveTokens,
    resetPass,
    changePass,
    changePass2,
    isLoggedIn,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  const location = useLocation();
  let { user } = useAuth();

  if (!user?.tokens)
    return <Navigate to="/login" state={{ from: location }} replace />;

  return children;
}
