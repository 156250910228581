import styled from "styled-components";
import { Flex, Icon, Type } from "../ui";
import { useNavigate } from "react-router-dom";

const Wrap = styled.div`
  cursor: pointer;
  transition: opacity 0.15s ease;
  :hover {
    opacity: 0.7;
  }
`;

function BackArrow({ to }) {
  const navigate = useNavigate();

  return (
    <Wrap>
      <Flex onClick={() => navigate(to ? to : -1)}>
        <Type as="span" sx={{ lh: 0, pr: 10, c: "gray700", fs: 16, fw: 700 }}>
          Back
        </Type>{" "}
        <Icon name="arrowLeft" c="gray700" w={14} />
      </Flex>
    </Wrap>
  );
}

export default BackArrow;
