import React from "react";

const CategoryRender = (row) => {
  return (
    <div style={{ fontSize: "16px", color: "rgb(48, 61, 77)" }}>
      {row.category.categoryName}
    </div>
  );
};

export default CategoryRender;
