const fields = [
  {
    name: "chainID",
    label: "Network",
    required: true,
    select: true,
    data: "networks",
    mapData: "mapDataNetwork",
  },
  {
    name: "companyID",
    label: "Company",
    required: true,
    select: true,
    data: "companies",
    mapData: "mapDataCompany",
  },
  {
    name: "targetWallet",
    label: "Target Wallet",
    required: true,
  },
  {
    name: "address",
    label: "Address",
    required: true,
  },
  {
    name: "active",
    label: "Active",
    switch: true,
  },
];

export default fields;
