import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";
import { getConfidential, getPublic } from "../../utilities/functions";

const Context = createContext();

export function useCompany() {
  return useContext(Context);
}

const initState = {
  list: [],
  documents: [],
  Confidential: [],
  Public: [],
  selected: null,
  keyFile: null,
  fileName: null,
  selectedfile: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_documents":
      return { ...state, documents: [...action.payload] };
    case "set_Confidential":
      return { ...state, Confidential: [...action.payload] };
    case "set_public":
      return { ...state, Public: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_keyFile":
      return { ...state, keyFile: action.payload };
    case "set_fileName":
      return { ...state, fileName: action.payload };
    case "set_selectedfile":
      return { ...state, selectedfile: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.documents];
      return { ...state, documents: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.documents.filter((p) => p.id !== action.payload);
      return { ...state, documents: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });
      let query;
      query = `statusID=${data?.statusID}&isFeatured=${data?.isFeatured}&categoryID=${data?.categoryID}`;
      const resData = await req(`company?${query}`, null, {}, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data });
    },

    [req]
  );
  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`company/${id}/`, null, {}, true);
        dispatch({ type: "set_selected", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );

  /*Document  */
  const removeDocs = useCallback(
    async (id) => {
      dispatch({ type: "status", payload: `deleting ${id}` });
      await req(`company/document/${id}`, {}, { method: "DELETE" }, true);
      dispatch({ type: "status", payload: "idle" });
      dispatch({ type: "delete", payload: id });
    },
    [req]
  );

  const fetchDocument = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        const resData = await req(`company/${id}/document`, null, {}, true);
        dispatch({ type: "set_documents", payload: resData.data });
        dispatch({
          type: "set_Confidential",
          payload: getConfidential(resData?.data),
        });
        dispatch({ type: "set_public", payload: getPublic(resData?.data) });

        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );

  const addDetailToDocs = useCallback(
    async (id, data) => {
      console.log(data);
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        const resData = await req(
          `company/${id}/document`,
          { documentList: [newData[0]] },
          { method: "POST" },
          true
        );
        dispatch({ type: "add", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(newData);
      });
    },
    [req]
  );
  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `company/${id}/update`,
            data,
            { method: "PATCH" },
            true
          );
          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const editFeature = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `company/${id}/setFeature`,
            data,
            { method: "PATCH" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  //update status
  const UpdateStatus = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `company/${id}/setStatus`,
            data,
            { method: "PATCH" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  //add image to slider
  const addImageSlider = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `company/${id}/addImageSlider`,
            data,
            { method: "PATCH" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const removeImageFromSlider = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `deleting ${id}` });

        try {
          const resData = await req(
            `company/${id}/deleteImageSlider`,
            { imageURL: data },
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "delete", payload: id });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  //deploy
  const deploy = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        const newData = { ...data };
        try {
          const resData = await req(
            `company/${id}/deploy`,
            "",
            { method: "POST" },
            true
          );
          dispatch({ type: "add", payload: resData.data });
          resolve(resData);
        } catch (e) {
          reject(e);
        }
      });
    },
    [req]
  );
  const updateImage = useCallback(
    async (id, avatar) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `loading` });
          let formData = new FormData();
          formData.append("avatar", avatar);
          const resData = await req(
            `company/${id}/setImage/`,
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "edit", payload: resData });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        }
      });
    },
    [req]
  );
  //get file from api
  const fetchImage = useCallback(
    (key) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        let query = `key =${key}`;
        const resData = await req(`upload/image?${query}`, null, {}, true);
        dispatch({ type: "set_selectedfile", payload: resData });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  //upload file
  const uploadFile = useCallback(
    async (avatar) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `loading` });
          let formData = new FormData();
          formData.append("avatar", avatar);

          const resData = await req(
            `upload/s3/public`,
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "status", payload: `idle` });
          dispatch({ type: "set_keyFile", payload: resData?.data?.key });

          dispatch({
            type: "set_fileName",
            payload: resData?.data?.originalname,
          });
          // dispatch({ type: "set_avatarURL", payload: resData.data.key });
          resolve(resData, "save avatar");
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        fetchOne,
        dispatch,
        fetchDocument,
        removeDocs,
        addDetailToDocs,
        addImageSlider,
        removeImageFromSlider,
        edit,
        deploy,
        editFeature,
        fetchImage,
        UpdateStatus,
        updateImage,
        uploadFile,
      }}
    >
      {children}
    </Context.Provider>
  );
}
