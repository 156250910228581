// React
import React from "react";
// UI
import { Icon, Box } from "../../../ui";
import Avatar from "../../../ui/Avatar";

const OptionsRender = ({ row, handleShowDelete = () => {} }) => {
  return (
    <Box>
        <Avatar
          className="avatar"
          onClick={() => handleShowDelete(true, row.id)}
        >
          <Icon name="Trash" w={16} c="primary" />
        </Avatar>
    </Box>
  );
};

export default OptionsRender;
