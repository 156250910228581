import styled from "styled-components";
import Box from "./Box";
import Flex from "./Flex";
import Loading from "./Loading";
import Mask from "./Mask";
import Type from "./Type";
import Avatar from "./Avatar";
import Icon from "./Icon";
import MoreOptions from "./tableContainer/MoreOptions";
import Grid from "./Grid";
import Chip from "./Chip";
import { useState } from "react";
import { copyToClipBoard } from "../utilities/functions";

const Wrap = styled(Box).attrs((p) => ({
  elevation: Number.isInteger(p.elevation) ? p.elevation : 1,
  w: p.w && p.w,
}))`
  box-shadow: ${(p) =>
    `${6 + 2 * p.elevation}px ${6 + 2 * p.elevation}px ${
      10 + 3 * p.elevation
    }px 0px ${p.theme.color[`boxShadow${p.elevation < 0 ? 0 : p.elevation}`]}`};
  background-color: ${(p) => p.theme.color[p.bg || "white"]};
  border-radius: ${(p) => (p.rad ? p.rad : "8")}px;
  border: ${(p) => p.theme.color.gray100} 1px solid;
  position: relative;
`;

const Head = styled.div`
  padding: ${(p) => p.padding || "20px 30px"};
  border-bottom: ${(props) => props.theme.color.gray300} 1px solid;
`;

const Body = styled.div`
  padding: ${(p) => p.padding || "20px 30px"};
  position: relative;
  @media only print {
    width: 90vw;
  }
`;

function CardGrid({
  title,
  children,
  onClose,
  loading = null,
  padding,
  iconName,
  avatarName,
  headName,
  roleBox = true,
  data,
  empty,
  cols,
  moreOptions = false,
  ...props
}) {
  const [CopySuccess, setCopySuccess] = useState("");

  const renderCell = (col, i, d) => {
    if (col.gridRender) {
      const Col = col.gridRender;
      return <Col {...d} {...col.renderParams} index={i} />;
    }
    return (
      <Type
        style={col.styles || {}}
        sx={{ fs: 13 }}
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title={d[col.name]}
      >
        {!d[col.name] && (
          <Type sx={{ c: "gray500", fs: 13 }}>Not Specified</Type>
        )}
        {d[col.name]?.length > 15 ? d[col.name]?.slice(0, 15) : d[col.name]}
        {d[col.name]?.length > 15 && "..."}
        {d[col.name] && (
          <Icon
            w={12}
            name="Copied"
            onClick={() =>
              copyToClipBoard(d[col.name], setCopySuccess, CopySuccess)
            }
            hover="gray300"
          />
        )}
      </Type>
    );
  };
  return !empty ? (
    <Grid {...{ ...props, rows: props.gridRows, cols: props.gridCols }}>
      {data?.map((d, i) => (
        <Wrap {...props} key={i} sx={{ mt: 20 }}>
          {Boolean(title) && (
            <Head padding={padding}>
              <Flex ai="flex-start" jc="space-between" sx={{ mt: 20 }}>
                <Type></Type>
                <Flex
                  jc="center"
                  ai="center"
                  dir="column"
                  gap="10"
                  sx={{ ml: 20 }}
                >
                  <Avatar
                    className="avatar"
                    style={{
                      width: "150px",
                      height: "150px",
                      marginTop: "-100px",
                      borderRadius: "40px",
                    }}
                    // borderRadius={1}
                  >
                    {d[avatarName] ? (
                      <img src={d[avatarName]} alt="user profile avatar" />
                    ) : (
                      <Icon name={iconName} w={14} c="gray600" />
                    )}
                  </Avatar>

                  <Type var="h5" as="h5" sx={{ m: 0 }}>
                    {headName ? d[headName] : null}
                  </Type>
                  {roleBox && (
                    <Chip bgc="secondary" c="white" borderRadius={"4px"}>
                      {d?.roles?.map((role, idRole) =>
                        idRole === d?.roles?.length - 1 ? role : role + " , "
                      )}
                    </Chip>
                  )}
                </Flex>

                {moreOptions && (
                  <MoreOptions
                    data={d}
                    moreOptions={moreOptions}
                    dotStyle="Horizontal"
                  />
                )}
              </Flex>
            </Head>
          )}
          <Body padding={padding}>
            {cols?.map((col, index) => {
              return (
                col.gridViewOnLabel && (
                  <Flex jc="space-between" key={index}>
                    <Type sx={{ mb: 5, fs: 12 }}>{col.label}</Type>

                    {/* {d[col.name]} */}
                    {renderCell(col, index, d)}
                  </Flex>
                )
              );
            })}
          </Body>
          {Boolean(loading !== null) && (
            <Mask relative show={loading} style={{ borderRadius: "15px" }}>
              <Loading />
            </Mask>
          )}
        </Wrap>
      ))}
    </Grid>
  ) : (
    <Flex jc="center">
      <Wrap {...props}>
        <Head padding={padding}>
          <Type sx={{ mb: 5, fs: 15 }}>No data available</Type>
        </Head>
      </Wrap>
    </Flex>
  );
}

export default CardGrid;
