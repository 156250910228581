import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.span`
  display: inline-block;
  border: ${(props) => props.size / 9 + "px"} solid transparent;
  border-top: ${(props) => props.size / 9 + "px"} solid
    ${(props) => props.theme.color.grayLight};
  border-radius: 50%;
  width: ${(props) => props.size + "px"};
  height: ${(props) => props.size + "px"};
  animation: ${rotate} 1.2s linear infinite;
`;

function Loading({ size = "32" }) {
  return <Loader className="loading" size={size} />;
}

export default Loading;
