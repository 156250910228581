import React, { useEffect, useState } from "react";

import { Box, Button, Card, Flex, Grid, Icon, Link, Type } from "../../ui";

import { useCompany } from "./Provider";
import { useParams } from "react-router-dom";

import fields from "./fields";
import {
  calminStakingPeriod,
  copyToClipBoard,
  viewDate,
} from "../../utilities/functions";
import request from "../../services/request";
import CompanyUpdate from "./CompanyUpdate";
import ErrorsList from "../../ui/ErrorsList";
import { useUtils } from "../../services/utilsProvider";
import Avatar from "../../ui/Avatar";
import Dialog from "../../ui/Dialog";

const CompanyDetail = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [reload, setreload] = useState(false);

  const [uploadImage, setUploadImage] = useState(false);

  const { notify } = useUtils();

  const {
    state: { selected: company },
    fetchOne: fetchCompany,
    deploy,
    updateImage,
  } = useCompany();

  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [editCheck, seteditCheck] = useState(false);
  const [UrlImage, setUrlImage] = useState("");
  const [viewImage, setViewImage] = useState("");
  const [CopySuccess, setCopySuccess] = useState("");
  const [Error, setError] = useState({ title: "" });
  const [Show, setShow] = useState(false);

  useEffect(() => {
    fetchCompany(id);
  }, [fetchCompany, editCheck, reload, id]);

  useEffect(() => {
    (async () => {
      if (company && !uploadImage && company?.imageURL) {
        const response = await request(
          `upload/image/?key=${company?.imageURL}`,
          null,
          {
            headers: {
              "Content-Type": "image",
            },
          },
          true
        );
        const imageBlob = await response.blob();
        setViewImage(URL.createObjectURL(imageBlob));
      }
    })();
  }, [company, uploadImage]);

  const handleSuccess = () => {
    notify("Company was updated successfully!!", "success");
    seteditCheck(false);
  };
  const deployClick = async () => {
    setloading(true);
    try {
      await deploy(id);
      notify("Company was deployed successfully!!", "success");
      setloading(false);
    } catch (error) {
      console.log(error);
      notify(error.errors.message, "alert");
      setloading(false);
    }
  };
  const fileSelectedHandler1 = async (e) => {
    var imagedata = document.querySelector("#file1").files[0];
    setUploadImage(true);
    setUrlImage(URL.createObjectURL(imagedata));
    var avatar = document.querySelector("#file1").files[0];
    try {
      await updateImage(id, avatar);
      notify("Image was uploaded successfully!!", "success");
      setUploadImage(true);
    } catch (error) {
      console.log(error, "error");
      setError({ title: error.errors.message });
    }
  };

  if (!company) return;

  return (
    <Box>
      <Type as="h1" var="Title" sx={{ mb: 25 }}>
        Company Details
      </Type>
      <Box sx={{ mb: 20 }}>
        <ErrorsList error={Error} />
      </Box>
      <Flex jc="flex-start">
        <Card sx={{ w: 300, h: 562, pt: 10 }}>
          <Box sx={{ mb: 30 }}>
            {" "}
            <Flex>
              <Avatar style={{ width: "150px", height: "150px" }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                  src={UrlImage ? UrlImage : viewImage}
                  alt="LOGO"
                />
              </Avatar>
            </Flex>
            {!company?.tokenAddress && (
              <label>
                <Icon
                  name="Edit"
                  style={{
                    position: "absolute",
                    left: "135px",
                    top: "120px",
                    zIndex: "1",
                    backgroundColor: "white",
                  }}
                  circle
                  w={16}
                  hover="gray300"
                />
                <input
                  style={{ display: "none" }}
                  id="file1"
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  onChange={fileSelectedHandler1}
                />

                {/* <img
                  src={image}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                  }}
                  alt=""
                /> */}
              </label>
            )}
            <Flex>
              <Type sx={{ fs: 24, pt: 10 }}>{company?.companyName}</Type>
            </Flex>
          </Box>
          <hr />
          <Box sx={{ mb: 20, mt: 50 }}>
            {fields.map((field, index) => (
              <Box key={index}>
                {field.firstCard &&
                  !field.status &&
                  field.label !== "STATUS" && (
                    <>
                      {" "}
                      <Type var="Label" sx={{ mb: 10, fs: 12 }}>
                        {field.label}
                      </Type>
                      {!field.icon && (
                        <>
                          <Type key={index - 1} sx={{ mb: 16, fs: 14, mr: 10 }}>
                            {company[field.name]}
                            {"  "}
                          </Type>
                        </>
                      )}
                      {field.icon && (
                        <Flex key={index - 2} jc="flex-start">
                          <Type sx={{ mb: 16, fs: 14, mr: 10 }}>
                            {company[field.name]}
                            {"  "}
                          </Type>
                          <Icon
                            w={16}
                            name="Copied"
                            onClick={() =>
                              copyToClipBoard(
                                company.website,
                                setCopySuccess,
                                CopySuccess
                              )
                            }
                            hover="gray300"
                          />
                        </Flex>
                      )}
                    </>
                  )}
                {field.label === "STATUS" && (
                  <>
                    <Type var="Label" sx={{ mb: 10, fs: 12 }}>
                      {field.label}
                    </Type>
                    <Box
                      sx={{
                        mb: 10,
                        bgc: "red",
                        p: 5,
                        br: 8,
                        mw: 150,
                      }}
                    >
                      <Type sx={{ fs: 14, c: "white", ta: "center" }}>
                        {company?.[field.name]}
                      </Type>
                    </Box>
                  </>
                )}
                {field.status &&
                  (company[field.name] ? (
                    <Type var="Label" sx={{ mb: 16, fs: 12 }}>
                      {" "}
                      FEATURED
                    </Type>
                  ) : (
                    <Type var="Label" sx={{ mb: 15, fs: 12, c: "error" }}>
                      NOT FEATURED
                    </Type>
                  ))}
              </Box>
            ))}
          </Box>
        </Card>
        <Card sx={{ ml: 30, pt: 10 }}>
          <Box>
            {editCheck ? (
              <>
                <Dialog className="dialog" show={Show} setShow={setShow}>
                  <CompanyUpdate
                    company={company}
                    user={user}
                    onSuccess={handleSuccess}
                    setreload={setreload}
                  />
                </Dialog>
              </>
            ) : (
              <>
                <Flex jc="space-between">
                  <Type var="Heading" sx={{ mb: 15 }}>
                    Description
                  </Type>
                  {!company?.tokenAddress && (
                    <Icon
                      name="Update"
                      onClick={() => {
                        seteditCheck(true);
                        setShow(true);
                      }}
                    />
                  )}
                </Flex>
                <Type sx={{ mb: 20, fs: 15 }}>{company?.description}</Type>
                <Grid cols="200px 200px 170px 250px" gap={0}>
                  {fields.map((field, index) => {
                    return (
                      <React.Fragment key={index}>
                        {field.secondCard && (
                          <>
                            {" "}
                            <Type var="Label" sx={{ mb: 15, fs: 15 }}>
                              {field.label}
                            </Type>
                            <Flex>
                              {!field.date && (
                                <Type sx={{ mb: 20, mr: 5, fs: 15 }}>
                                  {company[field.name]}
                                </Type>
                              )}
                              {field.date && (
                                <Type sx={{ mb: 20, mr: 5, fs: 15 }}>
                                  {viewDate(company[field.name])}
                                </Type>
                              )}
                              {field.unit && (
                                <Type var="Label" sx={{ mb: 20, fs: 15 }}>
                                  {`(${field.unit})`}
                                </Type>
                              )}
                              {field.name === "minStakingPeriod" && (
                                <Type var="Label" sx={{ mb: 20, fs: 15 }}>
                                  {calminStakingPeriod(
                                    company.minStakingPeriod
                                  )}
                                </Type>
                              )}
                            </Flex>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <hr />

                <Box sx={{ mb: 20 }}>
                  <Type var="Heading" sx={{ mb: 15, mt: 15 }}>
                    Block Chain
                  </Type>
                  <Flex jc="flex-start">
                    <Type var="Label" sx={{ fs: 15, mb: 15, mr: 50 }}>
                      Network Name
                    </Type>
                    <Type sx={{ fs: 15, mb: 15 }}>
                      {user?.tokens?.blockchainData?.networkName}
                    </Type>
                  </Flex>
                  <Link
                    sx={{ c: "bodyLight" }}
                    to={`${user?.tokens?.blockchainData?.block_explorer_url}address/${company?.targetWallet}`}
                  >
                    Target Wallet Address
                  </Link>
                </Box>
                {!company?.tokenAddress && (
                  <Button
                    onClick={deployClick}
                    data-testid="deploy"
                    bg="secondary"
                    sx={{ mx: "auto", w: "100%", mt: 10 }}
                    loading={loading}
                    as="button"
                  >
                    Deploy
                  </Button>
                )}
              </>
            )}
          </Box>
        </Card>
      </Flex>
    </Box>
  );
};

export default React.memo(CompanyDetail);
