const fields = [
  {
    name: "chainID",
    label: "Chain ID",
    focus: true,
    required: true,
  },
  {
    name: "networkName",
    label: "Network Name",
    required: true,
  },
  {
    name: "currencySymbol",
    label: "Currency Symbol",
    required: true,
  },
  {
    name: "rpcURL",
    label: "RPC URL",
    type: "url",
    required: true,
  },
  {
    name: "blockExplorerURL",
    label: "Block Explorer URL",
    required: false,
  },
  {
    name: "cryptoAddress",
    label: "Crypto Address",
  },
  {
    name: "usedLibrary",
    label: "Used Library",
    select: true,
    data: "usedLibrary",
    mapData: "mapUsedLibrary",
  },
];

export default fields;
