// React
import React from "react";
// UI
import { Type, Flex, Icon } from "../../../ui";
import Avatar from "../../../ui/Avatar";

const StatusRender = (row) => {
  return (
    <Flex jc="flex-start">
      {/* Avatar circle */}
      <Avatar className="avatar">
        {row?.avatarURL ? (
          <img src={row?.avatarURL} alt="user profile avatar" />
        ) : (
          <Icon name="user" w={16} c="gray600" />
        )}
      </Avatar>
      {/* Name */}
      {/* <Type sx={{ ml: 10 }}>
        {row.name}
      </Type> */}
    </Flex>
  );
};

export default StatusRender;
