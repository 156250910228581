const noUnit = ["grid-column-start", "grid-column-end"];

export function buildRule(attr, value = null, defaultValue = "inherit") {
  if (!value && value !== 0) return `${attr}: ${defaultValue};`;
  if (typeof value === "string" || typeof value === "number")
    return `${attr}: ${
      typeof value === "number" && !noUnit.includes(attr) ? value + "px" : value
    };`;
  // else, we consider it an array
  else
    return `
        ${attr}: ${
      typeof value[0] === "number" && !noUnit.includes(attr)
        ? value[0] + "px"
        : value[0]
    };
        @media only screen and (max-width: 1200px) {
          ${attr}: ${
      typeof value[1] === "number" && !noUnit.includes(attr)
        ? value[1] + "px"
        : value[1]
    };
        }
        @media only screen and (max-width: 992px) {
          ${attr}: ${
      typeof value[2] === "number" && !noUnit.includes(attr)
        ? value[2] + "px"
        : value[2]
    };
        }
        @media only screen and (max-width: 768px) {
          ${attr}: ${
      typeof value[3] === "number" && !noUnit.includes(attr)
        ? value[3] + "px"
        : value[3]
    };
        }
    `;
}

// prettier-ignore
function stylesBlocks(props) {
  let s = "";

  if (props.center) s += "margin: 0 auto;";

  if (typeof props.sx?.my !== "undefined")
    s +=
      buildRule("margin-top", props.sx?.my) +
      buildRule("margin-bottom", props.sx?.my);

  if (typeof props.sx?.mx !== "undefined")
    s +=
      buildRule("margin-left", props.sx?.mx) +
      buildRule("margin-right", props.sx?.mx);

  if (typeof props.sx?.mt !== "undefined") s += buildRule("margin-top", props.sx?.mt);
  if (typeof props.sx?.mb !== "undefined") s += buildRule("margin-bottom", props.sx?.mb);
  if (typeof props.sx?.ml !== "undefined") s += buildRule("margin-left", props.sx?.ml);
  if (typeof props.sx?.mr !== "undefined") s += buildRule("margin-right", props.sx?.mr);
  if (typeof props.sx?.m !== "undefined") s += buildRule("margin", props.sx?.m);

  if (typeof props.sx?.py !== "undefined")
    s +=
      buildRule("padding-top", props.sx?.py) +
      buildRule("padding-bottom", props.sx?.py);

  if (typeof props.sx?.px !== "undefined")
    s +=
      buildRule("padding-left", props.sx?.px) +
      buildRule("padding-right", props.sx?.px);

  if (typeof props.sx?.pt !== "undefined") s += buildRule("padding-top", props.sx?.pt);
  if (typeof props.sx?.pb !== "undefined") s += buildRule("padding-bottom", props.sx?.pb);
  if (typeof props.sx?.pl !== "undefined") s += buildRule("padding-left", props.sx?.pl);
  if (typeof props.sx?.pr !== "undefined") s += buildRule("padding-right", props.sx?.pr);
  if (typeof props.sx?.p !== "undefined") s += buildRule("padding", props.sx?.p);
  if (typeof props.sx?.ff !== "undefined") s += buildRule("font-family", props.theme.fonts[props.sx.ff]);
  if (typeof props.sx?.fs !== "undefined") s += buildRule("font-size", props.sx.fs);
  if (typeof props.sx?.fw !== "undefined") s += buildRule("font-weight", props.sx.fw.toString());
  if (typeof props.sx?.lh !== "undefined") s += buildRule("line-height", props.sx.lh);
  if (typeof props.sx?.fstyle !== "undefined") s += buildRule("font-style", props.sx.fstyle);
  if (typeof props.sx?.text !== "undefined") s += buildRule("text-transform", props.sx.text);
  if (typeof props.sx?.ta !== "undefined") s += buildRule("text-align", props.sx.ta);
  if (typeof props.sx?.c !== "undefined") s += buildRule("color", props.theme.color[props.sx.c]);
  if (typeof props.sx?.bgc !== "undefined") s += buildRule("background-color", props.theme.color[props.sx.bgc]);
  if (typeof props.sx?.d !== "undefined") s += buildRule("display", props.theme.color[props.sx.d]);
  if (typeof props.sx?.w !== "undefined") s += buildRule("width", props.sx.w);
  if (typeof props.sx?.gap !== "undefined") s += buildRule("gap", props.sx.gap);
  if (typeof props.sx?.grow !== "undefined") s += buildRule("grow", props.sx.grow);
  if (typeof props.sx?.sqr !== "undefined")
    s += buildRule("width", props.sx.sqr) + buildRule("height", props.sx.sqr);
  if (typeof props.sx?.mw !== "undefined" && typeof props.sx.mw === "object") {
    props.sx.mw.forEach((bp) => {
      s += `@media only screen and (max-width: ${bp[0]}px) { max-width: ${
        !isNaN(bp[1]) ? bp[1] + "px" : bp[1]
      } }`;
    });
  } else if (typeof props.sx?.mw !== "undefined") s += buildRule("max-width", props.sx.mw);
  if (typeof props.sx?.maxw !== "undefined") s += buildRule("max-width", props.sx.maxw);
  if (typeof props.sx?.mxw !== "undefined") s += buildRule("max-width", props.sx.mxw);
  if (typeof props.sx?.minw !== "undefined") s += buildRule("min-width", props.sx.minw);
  if (typeof props.sx?.mnw !== "undefined") s += buildRule("min-width", props.sx.mnw);
  if (typeof props.sx?.h !== "undefined") s += buildRule("height", props.sx.h);
  if (typeof props.sx?.mh !== "undefined") s += buildRule("min-height", props.sx.mh);
  if (typeof props.sx?.mnh !== "undefined") s += buildRule("min-height", props.sx.mnh);
  if (typeof props.sx?.mxh !== "undefined") s += buildRule("max-height", props.sx.mxh);
  if (typeof props.sx?.maxh !== "undefined") s += buildRule("max-height", props.sx.maxh);
  if (typeof props.sx?.cursor !== "undefined") s += buildRule('cursor', props.sx.cursor);
  if (typeof props.sx?.of !== "undefined") s += buildRule('overflow', props.sx.of);
  if (typeof props.sx?.tr !== "undefined") s += buildRule('transition', props.sx.tr);
  if (typeof props.sx?.bc !== "undefined") s += buildRule('border-color', props.theme.color[props.sx.bc]);
  if (typeof props.sx?.bs !== "undefined") s += buildRule('border-style', props.sx.bs);
  if (typeof props.sx?.bw !== "undefined") s += buildRule('border-width', props.sx.bw);
  if (typeof props.sx?.br !== "undefined") s += buildRule('border-radius', props.sx.br);
  if (typeof props.sx?.border !== "undefined") 
    s += 
      buildRule('border-color', props.theme.color[props.sx.border[0]]) +
      buildRule('border-width', props.sx.border[1]) +
      buildRule('border-style', props.sx.border[2]) +
      buildRule('border-radius', props.sx.border[3] || 0);
  if (typeof props.sx?.op !== "undefined") s += buildRule('opacity', props.sx.op.toString());
  if (typeof props.sx?.gcs !== "undefined") s += buildRule('grid-column-start', props.sx.gcs);
  if (typeof props.sx?.gce !== "undefined") s += buildRule('grid-column-end', props.sx.gce);
  if (typeof props.sx?.grs !== "undefined") s += buildRule('grid-row-start', props.sx.grs);
  if (typeof props.sx?.gre !== "undefined") s += buildRule('grid-row-end', props.sx.gre);
  if (typeof props.sx?.gr !== "undefined") s += buildRule('grid-row', props.sx.gr);
  if (typeof props.sx?.gc !== "undefined") s += buildRule('grid-column', props.sx.gc);

  return s;
}

export default stylesBlocks;
