import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import Clients from "./Clients";
import ClientsList from "./ClientsList";
import ClientDetail from "./ClientDetail";

export default (
  <Route
    path="clients"
    exact
    element={
      <RequireAuth>
        <Clients />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <ClientsList />
        </RequireAuth>
      }
    />
    <Route
      path="detail/:id"
      element={
        <RequireAuth>
          <ClientDetail />
        </RequireAuth>
      }
    />
  </Route>
);
