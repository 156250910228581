import styled from "styled-components";
import { useAuth } from "../../services/auth";
import { Box, Button, Flex, Icon, Link, Type } from "../../ui";
import Avatar from "../../ui/Avatar";
import Card from "../../ui/Card";
import Dropdown from "../../ui/core/Dropdown";
import { getInitials } from "../../utilities/functions";
import useImageLink from "../../utilities/hooks/useImageLink";

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  li {
    margin: 10px 0;
    font-size: 15px;
  }
`;

const Sflex = styled(Flex)`
  cursor: pointer;
`;

function UserInfo(props) {
  const { user } = useAuth();

  return (
    <Box sx={{ fs: 13, c: "gray300" }} {...props}>
      <Box sx={{ m: 5 }}>
        <Type as="strong">
          {user?.tokens?.user?.firstName} {user?.tokens?.user?.lastName}
        </Type>
      </Box>
    </Box>
  );
}

function ProfileOpts() {
  const { signout } = useAuth();

  return (
    <Card sx={{ minw: 180 }}>
      <UserInfo className="h-lg" sx={{ mb: 30 }} />

      <List>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/profile">Profile</Link>
        </li>
      </List>
      <Button
        size="small"
        variant="outlined"
        bg="secondary"
        sx={{ w: "100%" }}
        onClick={signout}
      >
        Logout
      </Button>
    </Card>
  );
}

function UserAccount() {
  const { user } = useAuth();
  const imgUrl = useImageLink(user?.user?.avatarURL);

  return (
    <Dropdown content={<ProfileOpts />}>
      <Sflex>
        <Avatar className="avatar">
          {imgUrl ? (
            <img src={imgUrl} alt="user profile avatar" />
          ) : (
            // getInitials(user?.user?.firstName + " " + user?.user?.lastName)
            <Icon name="user" w={16} c="gray600" />
          )}
        </Avatar>

        {/* <UserInfo className="h-md" sx={{ ml: 10 }} /> */}
      </Sflex>
    </Dropdown>
  );
}

export default UserAccount;
