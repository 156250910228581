import Card from "./Card";
import Close from "./Close";
import Mask from "./Mask";
import Modal from "./Modal";

function Dialog({ children, show, setShow }) {
  return (
    <>
      {show && (
        <>
          <Mask show={show} />
          <Modal show={show}>
            <Card>
              <Close
                x={-12}
                y={-12}
                size="small"
                onClick={() => setShow(false)}
              />
              {children}
            </Card>
          </Modal>
        </>
      )}
    </>
  );
}
export default Dialog;
