// React
import React, { useState } from "react";
// UI
import { Type, Icon } from "../../../ui";
import { copyToClipBoard } from "../../../utilities/functions";

const UrlRender = (row, name, type) => {
  const [CopySuccess, setCopySuccess] = useState("");

  return (
    <Type
      sx={{ ml: 10 }}
      data-bs-toggle="tooltip"
      data-bs-placement="bottom"
      title={row[name]}
    >
      {row[name] && (
        <Icon
          w={12}
          name="Copied"
          onClick={() =>
            copyToClipBoard(row[name], setCopySuccess, CopySuccess)
          }
          hover="gray300"
        />
      )}
      {!row[name] && <Type sx={{ c: "gray500", fs: 14 }}>Not Specified</Type>}
      {row[name].split("https://")[1]?.length > 15
        ? row[name].split("https://")[1]?.slice(0, 15)
        : row[name].split("https://")[1]}
      {row[name].split("https://")[1]?.length > 15 && "..."}
    </Type>
  );
};

export default UrlRender;
