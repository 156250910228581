// React
import React, { useEffect, useState } from "react";
// UI
import {
  Box,
  Button,
  CardGrid,
  Flex,
  Icon,
  TableContainer,
  Type,
} from "../../ui";
import Dialog from "../../ui/Dialog";
// CSS
import "../../css/flags.css";
// Providers
import { useCategories } from "./Provider";
// Renders
import OptionsRender from "./CellRender/OptionsRender";
// Forms
import AddCategory from "./AddCategory";
import { useUtils } from "../../services/utilsProvider";
import UpdateCategory from "./UpdateCategory";

const CategoryList = () => {
  // Use States
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [category, setCategory] = useState(null);
  // Providers
  const {
    state: { list: Categories },
    fetchList: fetchCategories,
    remove,
  } = useCategories();
  const { notify } = useUtils();
  const cols = [
    {
      label: "ID",
      name: "id",
      gridViewOnHeader: true,
    },
    { label: "CATEGORY NAME", name: "categoryName", gridViewOnLabel: true },
  ];
  // Use Effects
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
  // Handling
  const handleShowUpdate = (flag, row) => {
    setShowUpdate(flag);
    console.log(row, "row");
    setCategory(row);
  };
  const handleShowDelete = (flag, id) => {
    setShowDelete(flag);
    setDeletedId(id);
  };
  const handleDelete = async (id) => {
    try {
      await remove(id);
      setShowDelete(false);
      notify("Category Deleted Successfully");
    } catch (error) {
      notify(error?.errors?.message, "alert");
    }
  };

  return (
    <Box data-testid="test-1">
      {/* Header With Icons */}
      <Flex jc="space-between" sx={{ mb: 15 }}>
        {/* Title */}
        <Type as="h1" var="Title">
          Categories{" "}
          <span
            style={{ color: "#8697A8", fontWeight: "600" }}
          >{`(${Categories?.length})`}</span>
        </Type>
      </Flex>

      {/* Plus Icon */}
      <Flex jc="flex-end" sx={{ mb: 15 }}>
        <Icon
          name="Plus"
          w={16}
          c="secondary"
          hover={true}
          hoverBgc="secondary"
          hoverOpacity={0.8}
          onClick={() => setShow(true)}
          circle
        />
        <Dialog className="dialog" show={show} setShow={setShow}>
          <AddCategory setShow={setShow} />
        </Dialog>
      </Flex>

      {/* Table */}
      <Box>
        <TableContainer
          empty={Categories?.length === 0 ? true : false}
          data={Categories}
          cols={cols}
          // tableProps={{ borderCollapse: "seperate", borderSpacing: " 0 8px" }}
          moreOptions={[
            {
              name: "delete",
              render: (row) =>
                OptionsRender({
                  row,
                  handleShow: handleShowDelete,
                  name: "delete",
                }),
            },
            {
              name: "update",
              render: (row) =>
                OptionsRender({
                  row,
                  handleShow: handleShowUpdate,
                  name: "update",
                }),
            },
          ]}
        />
      </Box>

      {/* Delete Dialog */}
      <Dialog className="dialog" show={showDelete} setShow={setShowDelete}>
        <Flex jc="center" ai="center" dir="column" gap="22">
          <Box>
            <Icon name="Trash" w={40} c="primary" />
          </Box>
          <Type sx={{ mt: 20, fs: 20 }}>
            Are you sure you want to delete this item?
          </Type>
          <Flex jc="center" ai="center" dir="column" gap="10">
            <Button
              sx={{ fs: 15, w: 400 }}
              onClick={() => handleDelete(deletedId)}
            >
              Delete
            </Button>
            <Button
              bg="grayButton"
              sx={{ c: "black", fs: 15, w: 400 }}
              onClick={() => setShowDelete(false)}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Dialog>

      {/* Update Dialog */}
      <Dialog className="dialog" show={showUpdate} setShow={setShowUpdate}>
        <UpdateCategory setShow={setShowUpdate} category={category} />
      </Dialog>
    </Box>
  );
};

export default CategoryList;
