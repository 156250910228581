import React from "react";
import { Box, Flex, Type } from "../../ui";
import styled from "styled-components";
import { calcHeight } from "../../utilities/functions";

const Shape = styled.div`
  border-radius: 9px;
  margin-left: 100px;
  background-color: ${(p) =>
    (p.mBalance >= p.lvls?.good && "#8cfe57") ||
    (p.mBalance < p.lvls?.low && "#FF3333F5") ||
    (p.mBalance < p.lvls?.good && p.mBalance >= p.lvls?.low && "#FFA15C")};
  width: 150px;
  height: 40px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  div {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid
      ${(p) =>
        (p.mBalance >= p.lvls?.good && "#8cfe57") ||
        (p.mBalance < p.lvls?.low && "#FF3333F5") ||
        (p.mBalance < p.lvls?.good && p.mBalance >= p.lvls?.low && "#FFA15C")};
    margin-left: 40px;
  }
`;

const Chart = ({ maticBalance, currency, levels }) => {
  return (
    <Box sx={{ mt: 20, ml: 20 }}>
      <Flex jc="flex-start">
        <Box>
          <Box>
            <Shape mBalance={maticBalance} lvls={levels}>
              {" "}
              {(maticBalance >= levels?.good && <>High</>) ||
                (maticBalance < levels?.low && <>Low</>) ||
                (maticBalance < levels?.good && maticBalance >= levels?.low && (
                  <>Good</>
                ))}{" "}
              Liquidity
              <div></div>
            </Shape>
          </Box>
          <Flex jc="flex-start" ai="end">
            <Box>
              <div
                style={{
                  marginLeft: "150px",
                  marginTop: "20px",
                  width: "38px",
                  height: calcHeight(maticBalance, levels),
                  backgroundColor:
                    (maticBalance >= levels?.good && "#8cfe57") ||
                    (maticBalance < levels?.low && "#FF3333F5") ||
                    (maticBalance < levels?.good &&
                      maticBalance >= levels?.low &&
                      "#FFA15C"),
                  borderRadius: "9px",
                }}
              ></div>
            </Box>
            <Box sx={{ ml: "10px" }}>
              <Type sx={{ mt: "50px" }}>{levels?.good}</Type>
              <Type sx={{ mt: "50px" }}>{levels?.low}</Type>
              <Type sx={{ mt: "50px" }}>0</Type>
            </Box>
          </Flex>
          <Type var="Heading" sx={{ ml: 140, fs: 13 }}>
            {" "}
            {currency}: {maticBalance}
          </Type>
        </Box>
      </Flex>
    </Box>
  );
};

export default Chart;
