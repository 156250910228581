import React, { useEffect, useState } from "react";
import { useCompany } from "./Provider";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Icon,
  InputText,
  TableContainer,
  Type,
} from "../../ui";
import FixedRender from "./render/FixedRender";
import Avatar from "../../ui/Avatar";
import { useUtils } from "../../services/utilsProvider";
import { getConfidential, getPublic } from "../../utilities/functions";
import Dialog from "../../ui/Dialog";
import UploadFile from "./UploadFile";

const Documents = () => {
  const { id } = useParams();
  const { notify } = useUtils();
  const [Show, setShow] = useState(false);
  const [isConfidential, setIsConfidential] = useState();
  const {
    state: { documents, Confidential, Public },
    fetchDocument,
    removeDocs,
  } = useCompany();

  const cols = [
    { label: "Name", name: "documentName" },
    { label: "Fixed", name: "isFixed", render: FixedRender },
  ];
  useEffect(() => {
    fetchDocument(id);
  }, [fetchDocument, id]);

  const handleIconDelete = async (ID) => {
    try {
      await removeDocs(ID);
      notify("a document has been removed successfully.", "success");
    } catch (error) {
      notify(error.errors.message + "alert");
    }
  };
  const addPublic = async () => {
    setIsConfidential(0);
    setShow(true);
  };
  if (!documents) return;
  return (
    <>
      <Type as="h1" var="Title" sx={{ mb: 25 }}>
        Company Documents
      </Type>
      {Public && (
        <Box sx={{ mt: 20 }}>
          <Card>
            <Flex jc="space-between">
              <Type as="h1" var="Title" sx={{ mb: 25, fs: 18 }}>
                Public Documents
              </Type>
              <Button bg="secondary" onClick={addPublic}>
                Upload
              </Button>
            </Flex>
            <TableContainer
              empty={Public?.length === 0 ? true : false}
              data={Public}
              cols={cols}
              moreOptions={[
                {
                  name: "delete",
                  render: (row) => (
                    <Box>
                      <Avatar onClick={() => handleIconDelete(row?.id)}>
                        <Icon name="Trash" w={16} c="primary" />
                      </Avatar>
                    </Box>
                  ),
                },
              ]}
            />
          </Card>
        </Box>
      )}
      {Confidential && (
        <Box sx={{ mt: 10 }}>
          <Card>
            <Flex jc="space-between">
              <Type as="h1" var="Title" sx={{ mb: 25, fs: 18 }}>
                Confidential Documents
              </Type>
              <Button>Upload</Button>
            </Flex>
            <TableContainer
              empty={Confidential?.length === 0 ? true : false}
              data={Confidential}
              cols={cols}
              moreOptions={[
                {
                  name: "delete",
                  render: (row) => (
                    <Box>
                      <Avatar onClick={() => handleIconDelete(row?.id)}>
                        <Icon name="Trash" w={16} c="primary" />
                      </Avatar>
                    </Box>
                  ),
                },
              ]}
            />
          </Card>
        </Box>
      )}
      <Dialog className="dialog" show={Show} setShow={setShow}>
        <UploadFile
          isConfidential={isConfidential}
          show={Show}
          setShow={setShow}
          id={id}
        />
      </Dialog>
    </>
  );
};

export default Documents;
