import React from "react";
import USDTForm from "./USDTForm";
import { Type } from "../../ui";
import { useUtils } from "../../services/utilsProvider";

const USDTAdd = ({ show, setShow }) => {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("a new USDT has been created successfully.");
    setShow(false);
  };
  return (
    <div>
      <Type as="h1" var="Heading" sx={{ mb: 40 }}>
        New USDT
      </Type>
      <USDTForm handleSuccess={handleSuccess} />
    </div>
  );
};

export default USDTAdd;
