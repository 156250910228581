// React
import { useState } from "react";
import { useForm } from "react-hook-form";
// Yup
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// UI
import { Box, Button, InputText, Link, Type } from "../../../ui";
// CSS
import "../../../css/flags.css";
// Auth
import { useAuth } from "../../../services/auth";
// Schema
const schema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

function ForgotForm({ onSuccess }) {
  // Use State
  const [error, setError] = useState(null);
  // Auth
  let auth = useAuth();
  // Use Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Handling
  const handleError = ({ status, errors: e }) => {
    if (status === 400) {
      if (e.message === "UNVERIFIED_EMAIL")
        setError(
          <Type sx={{ c: "red", ta: "center", mt: -20, mb: 60 }}>
            Unverified Email.
          </Type>
        );
      else if (e.message === "INVALID_USER")
        setError(
          <Type sx={{ c: "red", ta: "center", mt: -20, mb: 60 }}>
            Invalid User.
          </Type>
        );
      else
        setError(
          <Type sx={{ c: "red", ta: "center", mt: -20, mb: 60 }}>
            {e.message}
          </Type>
        );
    } else if (status === 429) {
      setError(
        <Type sx={{ c: "red", ta: "center", mt: -20, mb: 60 }}>
          Too many request. {e.data.message}
        </Type>
      );
    }
  };
  // Submit Form
  const onSubmit = async (values) => {
    setError(null);
    try {
      await auth.resetPass(values.email);
      reset();
      onSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      handleError(e);
      console.log("e", e);
    }
  };
  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{w: '70%', mxw: 500 }}
    >
      {error && error}
      {/* Email text */}
      <InputText
        label="Email"
        error={!!errors.email}
        msg={errors.email?.message}
        width="100%"
        inputProps={{
          ...register("email"),
          autoFocus: true,
        }}
      />
      <Button
        type="submit"
        loading={isSubmitting}
        size="large"
        bg="secondary"
        sx={{ mx: "auto", w: "100%" }}
      >
        Reset Password
      </Button>
      <Type sx={{ ta: "center", mt: 20 }}>
        Go back to <Link to="/login">Login</Link>
      </Type>
    </Box>
  );
}

export default ForgotForm;
