import React, { useEffect } from "react";
import { Box, Button, InputText } from "../../ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useCompany } from "./Provider";

const schema = Yup.object().shape({
  documentKey: Yup.string(),
  isConfidential: Yup.number(),
  documentName: Yup.string(),
});

const UploadFile = ({ isConfidential, Show, setShow, id }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    state: { keyFile, fileName },
    uploadFile: upload,
    addDetailToDocs,
  } = useCompany();
  const onSubmit = async (values) => {
    console.log(values, "val");
    var documentList = [];
    documentList.push({
      documentKey: keyFile,
      isConfidential: isConfidential,
      documentName: fileName,
    });
    try {
      await addDetailToDocs(id, documentList);
      setShow(false);
    } catch (e) {
      window.scrollTo(0, 0);
      console.error("e", e.message);
    }
  };
  const UploadFile = async () => {
    var avatar = document.querySelector("#file1").files[0];
    upload(avatar);
  };

  return (
    <Box sx={{ p: "40px" }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <InputText id="file1" onChange={UploadFile} type="file" />
        <Button
          type="submit"
          bg="secondary"
          sx={{ mx: "auto", w: "100%", mt: 10 }}
          loading={isSubmitting}
        >
          Save File
        </Button>
      </form>
    </Box>
  );
};

export default UploadFile;
