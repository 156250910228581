// React
import React, { useEffect, useState } from "react";
// UI
import {
  Box,
  Button,
  CardGrid,
  Flex,
  Icon,
  TableContainer,
  Type,
} from "../../ui";
import Dialog from "../../ui/Dialog";
// CSS
import "../../css/flags.css";
// Providers
import { useNetworks } from "./Provider";
// Renders
import NameRender from "./CellRender/NameRender";
import OptionsRender from "./CellRender/OptionsRender";
// Forms
import AddNetwork from "./AddNetwork";
import UpdateNetwork from "./UpdateNetwork";
import { useUtils } from "../../services/utilsProvider";
import TextRender from "./CellRender/TextRender";
import UrlRender from "./CellRender/UrlRender";
import UrlGridRender from "./CellRender/UrlGridRender";

const NetworksList = () => {
  // Use States
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [network, setNetwork] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [viewType, setViewType] = useState("grid");
  // Providers
  const {
    state: { list: networks },
    fetchList: fetchNetworks,
    remove,
  } = useNetworks();

  const { notify } = useUtils();
  const cols = [
    {
      label: "CHAIN ID",
      name: "chainID",
    },
    {
      label: "NETWORK NAME",
      name: "networkName",
      render: NameRender,
      gridViewOnHeader: true,
    },
    {
      label: "CURRENCY SYMBOL",
      name: "currencySymbol",
      gridViewOnLabel: true,
      render: (row) => TextRender(row, "currencySymbol"),
    },
    {
      label: "RPC URL",
      name: "rpcURL",
      gridViewOnLabel: true,
      render: (row) => UrlRender(row, "rpcURL"),
      gridRender: (row) => UrlGridRender(row, "rpcURL"),
    },
    {
      label: "BLOCK EXPLORER URL",
      name: "blockExplorerURL",
      gridViewOnLabel: true,
      render: (row) => UrlRender(row, "blockExplorerURL"),
      gridRender: (row) => UrlGridRender(row, "blockExplorerURL"),
    },
  ];
  // Use Effects
  useEffect(() => {
    fetchNetworks();
  }, [fetchNetworks]);
  // Handling
  const handleShowDelete = (flag, id) => {
    setShowDelete(flag);
    setDeletedId(id);
  };
  const handleShowUpdate = (flag, row) => {
    setShowUpdate(flag);
    setNetwork(row);
  };
  const handleDelete = async (id) => {
    try {
      await remove(id);
      setShowDelete(false);
      notify("Network Deleted Successfully");
    } catch (error) {
      notify(error?.errors?.message, "alert");
    }
  };

  return (
    <>
      {/* Header With Icons */}
      <Flex jc="space-between" sx={{ mb: 15 }}>
        {/* Title */}
        <Type var="Title">
          Networks{" "}
          <span
            style={{ color: "#8697A8", fontWeight: "600" }}
          >{`(${networks?.length})`}</span>
        </Type>

        {/* View Buttons */}
        <Flex jc="space-around" sx={{ gap: 15 }}>
          <Icon
            name="DashboardIcon"
            w={16}
            c="gray300"
            hover="black"
            onClick={() => setViewType("grid")}
            circle
          />
          <Icon
            name="ListIcon"
            w={16}
            c="gray300"
            hover="black"
            onClick={() => setViewType("list")}
            circle
          />
        </Flex>
      </Flex>

      {/* Plus Icon */}
      <Flex jc="flex-end" sx={{ mb: 15 }}>
        <Icon
          name="Plus"
          w={16}
          c="secondary"
          hover={true}
          hoverBgc="secondary"
          hoverOpacity={0.8}
          onClick={() => setShow(true)}
          circle
        />
      </Flex>
      <Dialog className="dialog" show={show} setShow={setShow}>
        <AddNetwork setShow={setShow} />
      </Dialog>
      {/* Table */}
      {viewType === "list" ? (
        <Box>
          <TableContainer
            empty={networks?.length === 0 ? true : false}
            data={networks}
            cols={cols}
            tableProps={{ borderCollapse: "seperate", borderSpacing: " 0 8px" }}
            moreOptions={[
              {
                name: "delete",
                render: (row) =>
                  OptionsRender({
                    row,
                    handleShow: handleShowDelete,
                    name: "delete",
                  }),
              },
              {
                name: "update",
                render: (row) =>
                  OptionsRender({
                    row,
                    handleShow: handleShowUpdate,
                    name: "update",
                  }),
              },
            ]}
          />
        </Box>
      ) : (
        <CardGrid
          roleBox={false}
          avatarName="icon"
          headName="networkName"
          iconName="user"
          data={networks}
          empty={networks?.length === 0 ? true : false}
          cols={cols}
          gridCols={"1fr 1fr 1fr"}
          gap={50}
          title={true}
          moreOptions={[
            {
              name: "delete",
              render: (row) =>
                OptionsRender({
                  row,
                  handleShow: handleShowDelete,
                  name: "delete",
                }),
            },
            {
              name: "update",
              render: (row) =>
                OptionsRender({
                  row,
                  handleShow: handleShowUpdate,
                  name: "update",
                }),
            },
          ]}
        />
      )}

      {/* Delete Dialog */}
      <Dialog className="dialog" show={showDelete} setShow={setShowDelete}>
        <Flex jc="center" ai="center" dir="column" gap="22">
          <Box>
            <Icon name="Trash" w={40} c="primary" />
          </Box>
          <Type sx={{ mt: 20, fs: 20 }}>
            Are you sure you want to delete this item?
          </Type>
          <Flex jc="center" ai="center" dir="column" gap="10">
            <Button
              sx={{ fs: 15, w: 400 }}
              onClick={() => handleDelete(deletedId)}
            >
              Delete
            </Button>
            <Button
              bg="grayButton"
              sx={{ c: "black", fs: 15, w: 400 }}
              onClick={() => setShowDelete(false)}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Dialog>
      {/* Update Dialog */}
      <Dialog className="dialog" show={showUpdate} setShow={setShowUpdate}>
        <UpdateNetwork setShow={setShowUpdate} network={network} />
      </Dialog>
    </>
  );
};

export default NetworksList;
