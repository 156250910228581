import React from "react";
import { Box, Type } from "../../../ui";

const mobileRender = (row) => {
  return (
    <Box>
      <Type var="tableTitle">{row?.user?.mobileNumber}</Type>
    </Box>
  );
};

export default mobileRender;
