// React
import React, { useState } from "react";
import { useForm } from "react-hook-form";
// Providers
import { useUsers } from "./Provider";
// UI
import { Box, Button, Flex, Icon, InputText, Type } from "../../ui";
// Fields
import fields from "./fields";
// YUP
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUtils } from "../../services/utilsProvider";
// Schema
const schema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(4, "Too Short, a password with at least 4 letters is required!"),
  mobileNumber: Yup.string().matches(/^[0-9]*$/, "Must be only digits"),
  // .max(9, "Mobile Number must be less than 9 digits"),
  avatarURL: Yup.string(),
});

const UserForm = ({ setShow }) => {
  // Use Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Providers
  const {
    state: { avatarURL },
    create,
    uploadAvatar,
  } = useUsers();
  const { notify } = useUtils();
  // Use State
  const [image, setimage] = useState("");
  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    values.avatarURL = avatarURL;
    try {
      await create(values);
      setShow(false);
      notify("User Added Successfully");
    } catch (error) {
      if (error.status === 400) {
        if (error.errors.response === 21) setError("User Already Exists");
        else setError("Username or Password are invalid");
      }
    }
  };

  const fileSelectedHandler1 = async () => {
    var avatar = document.querySelector("#file1").files[0];
    setimage(URL.createObjectURL(avatar));
    try {
      await uploadAvatar(avatar);
      setValue("avatarURL", avatarURL);
    } catch (e) {
      setError("Error Uploading Image");
    }
  };

  return (
    <>
      {/* Header */}
      <Flex jc="space-between" sx={{ mb: 20 }}>
        <Type as="h1" sx={{ fw: 600, fs: 28, mb: 20 }}>
          New User
        </Type>
      </Flex>
      {error && (
        <Type data-testid="err" sx={{ c: "primary", mb: 20 }}>
          {error}
        </Type>
      )}
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="formBox"
      >
        {/* Form */}
        <Flex jc="center" ai="center" dir="column" sx={{ w: 400 }}>
          {/* Avatar */}
          <label style={{ cursor: "pointer" }}>
            <input
              style={{ display: "none" }}
              id="file1"
              accept=".png, .jpg, .jpeg"
              type="file"
              onChange={fileSelectedHandler1}
              inputProps={{
                type: "file",
                ...register("avatarURL"),
                autoFocus: true,
              }}
            />
            {image ? (
              <img
                src={image}
                alt="avatar"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <Flex
                sx={{
                  border: ["gray500", "1px", "dashed", "8px"],
                  py: 40,
                  px: 40,
                  cursor: "pointer",
                }}
              >
                <Icon name="Plus" w={30} c="gray600" />
              </Flex>
            )}
          </label>

          {fields?.map((f, index) => (
            <InputText
              label={f?.label}
              name={f?.name}
              type={f?.type}
              data-testid={f?.name}
              key={index}
              error={!!errors[f?.name]}
              msg={errors[f?.name]?.message}
              style={{ width: "100%" }}
              inputProps={{
                ...register(f?.name),
                "aria-label": f?.name,
              }}
            />
          ))}
          <Button
            type="submit"
            bg="secondary"
            name="create"
            data-testid="create"
            sx={{ fs: "15px", w: 400, br: "8px" }}
          >
            Add User
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default UserForm;
