const fields = [
  {
    name: "website",
    label: "WEBSITE",
    firstCard: true,
    icon: true,
  },
  {
    name: "id",
    label: "COMPANY ID",
    firstCard: true,
  },
  {
    name: "statusDescription",
    label: "STATUS",
    firstCard: true,
  },
  {
    name: "isFeatured",
    label: "",
    firstCard: true,
    status: true,
  },
  {
    name: "totalShares",
    label: "Total Shares:",
    secondCard: true,
    unit: "Shares",
  },
  {
    name: "desiredTokenCap",
    label: "Desired Token Cap:",
    secondCard: true,
    unit: "Shares",
  },
  {
    name: "pricePerShare",
    label: "Price per Share:",
    secondCard: true,
    unit: "USDT",
  },
  {
    name: "minStakingPeriod",
    label: "Min Staking Period:",
    secondCard: true,
    unit: "sec",
  },
  {
    name: "minPurchase",
    label: "Min Purchase:",
    secondCard: true,
    unit: "Tokens",
  },
  {
    name: "rewardRate",
    label: "Reward Staking Rate:",
    secondCard: true,
    unit: "%",
  },
  {
    name: "purchaseLimit",
    label: "Purchase Limit:",
    secondCard: true,
  },
  {
    name: "stitvestRate",
    label: "Stitvest Commision:",
    secondCard: true,
    unit: "%",
  },
  {
    name: "geographicMarket",
    label: "Geographic Market:",
    secondCard: true,
  },
  {
    name: "startDateTime",
    label: "Start Date & Time:",
    secondCard: true,
    date: true,
  },
  {
    name: "headquarter",
    label: "Headquarter:",
    secondCard: true,
  },
  {
    name: "launchDateTime",
    label: "Close Date & Time:",
    secondCard: true,
    date: true,
  },
];

export default fields;
