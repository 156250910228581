import React, { useEffect, useState } from "react";
import { Box, Button, Card, Flex, Grid, Icon, Link, Type } from "../../ui";
import Avatar from "../../ui/Avatar";
import { useClient } from "./Provider";
import { useParams } from "react-router-dom";

const ClientDetail = () => {
  const params = useParams();
  const [Open, setOpen] = useState(false);
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));

  const {
    state: { selected: client },
    fetchOne: fetchClientInfo,
  } = useClient();

  useEffect(() => {
    fetchClientInfo(params?.id);
  }, [params?.id]);

  return (
    <>
      <Flex jc="space-between">
        <Type as="h1" var="Heading" sx={{ mb: 40 }}>
          Account
        </Type>
        <Grid cols="60px 50px" gap={0}>
          <Icon w={16} c="gray300" name="Edit" circle />
          <Icon w={16} c="gray300" name="Delete" circle />
        </Grid>
      </Flex>
      <Grid cols="300px 500px" gap={30}>
        <Card sx={{ w: 280 }}>
          <Flex jc="center" ai="center" dir="column">
            <Avatar
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "100px",
              }}
            >
              {client?.user?.avatarURL ? (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={client?.user?.avatarURL}
                  alt="avatar"
                />
              ) : (
                <Icon name="User" />
              )}
            </Avatar>
          </Flex>
          <Flex sx={{ mt: "30px", mb: "30px" }}>
            <Link
              sx={{ c: "white", bgc: "secondary", p: 6, br: 20 }}
              to={`${user?.tokens?.blockchainData?.block_explorer_url}address/${client?.walletAddress}`}
            >
              Target Wallet
            </Link>
          </Flex>
          <hr />
          <Box sx={{ pt: "20px", mt: "20px" }}>
            <Type var="Label" sx={{ fs: "12px" }}>
              EMAIL
            </Type>
            <Type sx={{ fs: "14px", mb: "30px" }}>{client?.user?.email}</Type>
            <Type var="Label" sx={{ fs: "12px" }}>
              PHONE
            </Type>
            <Type sx={{ fs: "14px", mb: "30px" }}>
              {client?.user?.mobileNumber}
            </Type>
            <Type var="Label" sx={{ fs: "12px" }}>
              LOCATION
            </Type>
            <Type sx={{ fs: "14px" }}>{client?.country?.country}</Type>
          </Box>
        </Card>
        <Card sx={{ w: 400, h: 249 }}>
          <Type var="Heading">Account Details</Type>
          <Box sx={{ mt: "40px" }}>
            <Flex jc="space-between">
              <Type var="Label" sx={{ fs: "15px" }}>
                First Name:
              </Type>
              <Type sx={{ fs: "14px" }}>{client?.firstName}</Type>
            </Flex>

            <Flex jc="space-between" sx={{ mt: "20px" }}>
              <Type var="Label" sx={{ fs: "15px" }}>
                Last Name:
              </Type>
              <Type sx={{ fs: "14px" }}>{client?.lastName}</Type>
            </Flex>
            <Flex jc="space-between" sx={{ mt: "20px" }}>
              <Type var="Label" sx={{ fs: "15px" }}>
                Date of Birth:
              </Type>
              <Type sx={{ fs: "14px" }}>{client?.dateOfBirth}</Type>
            </Flex>
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default ClientDetail;
