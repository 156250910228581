import React from "react";
import { Box, Icon } from "../../../ui";

const Active = (row) => {
  return (
    <Box>
      {row?.active ? <Icon name="ChechChecked" /> : <Icon name="checkClosed" />}
    </Box>
  );
};

export default Active;
