import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { Box, Card, Flex, Grid, Icon, Type } from "../../ui";
import Avatar from "../../ui/Avatar";
import { useProfile } from "./provider";
import { viewDate } from "../../utilities/functions";

const ProfileDetail = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("user")));

  const {
    state: { selected: userprofile },
    fetchOne: getDetailProfile,
  } = useProfile();

  useEffect(() => {
    getDetailProfile(user?.user?.id);
  }, [getDetailProfile, user?.user?.id]);

  return (
    <Layout>
      <Grid cols="300px 500px" gap={30}>
        <Card sx={{ w: 280 }}>
          <Flex jc="center" ai="center" dir="column">
            <Avatar
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "100px",
              }}
            >
              {userprofile?.avatarURL ? (
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={userprofile?.avatarURL}
                  alt="avatar"
                />
              ) : (
                <Icon name="User" />
              )}
            </Avatar>
          </Flex>
          <hr />
          <Box sx={{ pt: "20px", mt: "20px" }}>
            <Type var="Label" sx={{ fs: "12px" }}>
              EMAIL
            </Type>
            <Type sx={{ fs: "14px", mb: "30px" }}>{userprofile?.email}</Type>
            <Type var="Label" sx={{ fs: "12px" }}>
              PHONE
            </Type>
            <Type sx={{ fs: "14px", mb: "30px" }}>
              {userprofile?.mobileNumber}
            </Type>
            <Type var="Label" sx={{ fs: "12px" }}>
              Status
            </Type>
            {userprofile?.isActive ? (
              <Type sx={{ fs: "14px" }}>Active</Type>
            ) : (
              <Type sx={{ fs: "14px" }}>Not Active</Type>
            )}
          </Box>
        </Card>
        <Card sx={{ w: 450, h: 300 }}>
          <Type var="Heading">User Details</Type>
          <Box sx={{ mt: "40px" }}>
            <Flex jc="space-between">
              <Type var="Label" sx={{ fs: "15px" }}>
                Creation Date:
              </Type>
              <Type sx={{ fs: "14px" }}>
                {viewDate(userprofile?.creationDate)}
              </Type>
            </Flex>

            <Flex jc="space-between" sx={{ mt: "20px" }}>
              <Type var="Label" sx={{ fs: "15px" }}>
                Last Request Date:
              </Type>
              <Type sx={{ fs: "14px" }}>
                {viewDate(userprofile?.lastRequestDate)}
              </Type>
            </Flex>
            <Flex jc="space-between" sx={{ mt: "20px" }}>
              <Type var="Label" sx={{ fs: "15px" }}>
                Attempt Count:
              </Type>
              <Type sx={{ fs: "14px" }}>{userprofile?.attemptCount}</Type>
            </Flex>
            <Flex jc="space-between" sx={{ mt: "20px" }}>
              <Type var="Label" sx={{ fs: "15px" }}>
                Roles:
              </Type>
              {userprofile?.roles.map((role, index) => (
                <Type sx={{ fs: "14px" }} key={index}>
                  {role}
                </Type>
              ))}
            </Flex>
          </Box>
        </Card>
      </Grid>
    </Layout>
  );
};

export default ProfileDetail;
