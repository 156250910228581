import React from "react";
import USDTForm from "./USDTForm";
import { Type } from "../../ui";

const USDTUpdate = () => {
  return (
    <>
      <Type as="h1" var="Heading" sx={{ mb: 40 }}>
        Update USDT
      </Type>
      <USDTForm />
    </>
  );
};

export default USDTUpdate;
