import React from "react";
import { Box, Icon } from "../../../ui";

const FixedRender = ({ row }) => {
  return (
    <Box>
      {row?.isFixed ? (
        <Icon name="ChechChecked" />
      ) : (
        <Icon name="checkClosed" />
      )}
    </Box>
  );
};

export default FixedRender;
