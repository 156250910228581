import styled from "styled-components";
import Loading from "./Loading";

const StyledButton = styled.button.attrs((p) => ({
  bg: p.bg ? p.theme.color[p.bg] : p.theme.color.secondary,
  color: p.theme.color.gray50,
}))`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bg};
`;

function IconButton({ children, loading = false }) {
  return (
    <StyledButton>{loading ? <Loading size={20} /> : children}</StyledButton>
  );
}
export default IconButton;
