// React
import React from "react";
// UI
import { Icon, Box } from "../../../ui";
import Avatar from "../../../ui/Avatar";

const OptionsRender = ({ row, handleShow = () => {}, name = "delete" }) => {
  const getHandler = () => {
    switch (name) {
      case "delete":
        handleShow(true, row?.chainID);
        break;
      case "update":
        handleShow(true, row);
        break;
      default:
        console.log("do nothing");
    }
  };
  return (
    <Box sx={{ mb: 15 }}>
      <Avatar className="avatar" onClick={getHandler}>
        {name === "delete" ? (
          <Icon name="Trash" w={16} c="primary" />
        ) : (
          <Icon name="Edit" w={16} c="secondary" />
        )}
        {/* <Icon name="Trash" w={16} c="primary" /> */}
      </Avatar>
    </Box>
  );
};

export default OptionsRender;
