import styled from "styled-components";

const Toggle = styled.div`
  cursor: pointer;
  padding: 10px 6px;
  width: 18px;
  .bars {
    position: relative;
    height: 2px;
    width: 18px;
    background-color: ${(props) => props.theme.color.black};
    &::after {
      content: "";
      position: absolute;
      top: -6px;
      left: 0;
      height: 2px;
      width: 18px;
      background-color: ${(props) => props.theme.color.black};
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      height: 2px;
      width: 12px;
      background-color: ${(props) => props.theme.color.black};
    }
  }
`;

function ToggleSidebar(props) {
  return (
    <Toggle {...props}>
      <div className="bars" />
    </Toggle>
  );
}

export default ToggleSidebar;
