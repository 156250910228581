import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useClient() {
  return useContext(Context);
}

const initState = {
  list: [],
  wallet: [],
  selected: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "addWallet":
      const modifiedWallet = state.list.map((p) =>
        Number(p.id) === Number(action.payload.id)
          ? { ...p, ...action.payload }
          : p
      );
      return { ...state, list: modifiedWallet };
    // const newWallet = [{ ...action.payload }, ...state.wallet];
    // return { ...state, list: newWallet };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function CompanyProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(
    async (data) => {
      dispatch({ type: "status", payload: "fetching" });

      let query = `isVerified=${data}`;
      const resData = await req(`account?${query}`, null, {}, true);
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data || [] });
    },

    [req]
  );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `fetching` });
          const resData = await req(`account/profile/${id}/`, null, {}, true);
          dispatch({ type: "set_selected", payload: resData.data });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          reject(e);
        }
      });
    },
    [req]
  );

  const createWallet = useCallback(
    async (id, email) => {
      dispatch({ type: "status", payload: `creating wallet ${id}` });
      return new Promise(async (resolve, reject) => {
        try {
          const data = { clientID: parseInt(id), email: email };
          const newData = { ...data };
          const resData = await req(
            "account/createWalletOnMyBehalf/",
            newData,
            { method: "POST" },
            true
          );
          // const resData = await new Promise((resolve, reject) => setTimeout(() => resolve({}), 4000));
          dispatch({
            type: "addWallet",
            payload: { id, walletAddress: resData.data.publicKey },
          });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        fetchList,
        fetchOne,
        createWallet,
      }}
    >
      {children}
    </Context.Provider>
  );
}
