import { Outlet } from "react-router-dom";
import ScrollToTop from "./utilities/hooks/ScrollToTop";

const App = () => (
  <>
    <ScrollToTop />
    <Outlet />
  </>
);

export default App;
