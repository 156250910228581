import React from "react";
import { Box, Icon } from "../../../ui";

const verifiedRender = (row) => {
  return (
    <Box>
      {row?.isVerified ? (
        <Icon name="ChechChecked" />
      ) : (
        <Icon name="checkClosed" />
      )}
    </Box>
  );
};

export default verifiedRender;
