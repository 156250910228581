import styled from "styled-components";
import Card from "./Card";

export default styled(Card).attrs(p => ({
    bg: 'black',
  }))`
    border-radius: 6px;
    opacity: .8;
    font-size: 14px;
    color: ${p => p.theme.color.gray100};
    > div {
        padding: 10px 15px;
    }
`;