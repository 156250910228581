import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import Country from "./Country";
import CountryList from "./CountryList";
import CountryAdd from "./CountryAdd";
import CountryUpdate from "./CountryUpdate";

export default (
  <Route
    path="countries"
    exact
    element={
      <RequireAuth>
        <Country />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <CountryList />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <CountryAdd />
        </RequireAuth>
      }
    />
    <Route
      path="update"
      element={
        <RequireAuth>
          <CountryUpdate />
        </RequireAuth>
      }
    />
  </Route>
);
