import React from "react";
import CountryForm from "./CountryForm";
import { Type } from "../../ui";
import { useUtils } from "../../services/utilsProvider";

const CountryAdd = ({ show, setShow }) => {
  const { notify } = useUtils();

  const handleSuccess = () => {
    notify("a new country has been created successfully.");
    setShow(false);
  };
  return (
    <div>
      <Type as="h1" var="Heading" sx={{ mb: 40 }}>
        New Country
      </Type>
      <CountryForm handleSuccess={handleSuccess} />
    </div>
  );
};

export default CountryAdd;
