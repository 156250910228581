import styled from "styled-components";

export default styled.span`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "100px"};
  padding: 6px 10px;
  background-color: ${(props) => props.theme.color[props.bgc || "gray200"]};
  color: ${(props) => props.theme.color[props.c || "gray500"]};
  font-size: 16px;
  font-weight: 300;
  display: inline-block;
`;