import React from "react";
import { Box, Flex, Icon, Type } from "../../../ui";
import Avatar from "../../../ui/Avatar";

const CustomerName = (row) => {
  return (
    <Box w={200}>
      <Flex jc="flex-start">
        <Box sx={{ w: 40, h: 40, mr: 13 }}>
          <Avatar>
            {row.user?.avatarURL ? (
              <img src={row?.user?.avatarURL} alt="avatar" />
            ) : (
              <Icon w={15} name="User" />
            )}
          </Avatar>
        </Box>
        <Box>
          <Type var="tableTitle">
            {row?.firstName} {row.lastName}
          </Type>
          <Type var="Label" sx={{ fs: 14 }}>
            {row?.user?.email}
          </Type>
        </Box>
      </Flex>
    </Box>
  );
};

export default CustomerName;
