import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  .error {
    input {
        outline: ${props => (props.theme.color.red)} 1px solid !important;
    }
    .msg {
        color: ${props => (props.theme.color.red)};
    }
  }
`

export default GlobalStyle;