import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useCategories() {
  return useContext(Context);
}

const initState = {
  list: [],
  selected: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function CategoryProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(async () => {
    dispatch({ type: "status", payload: "fetching" });
    const resData = await req(`category`, null, {}, true);
    dispatch({ type: "status", payload: `idle` });
    dispatch({ type: "set_list", payload: resData.data.categoryList });
  }, [req]);

  const create = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        const newData = { ...data };
        const resData = await req(
          "category/",
          newData,
          { method: "POST" },
          true
        );
        dispatch({ type: "add", payload: resData.data });
        dispatch({ type: "status", payload: `idle` });
        resolve(resData);
      });
    },
    [req]
  );
  const edit = useCallback(
    async (id, data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `creating` });
        try {
          const resData = await req(
            `category/${id}`,
            data,
            { method: "PATCH" },
            true
          );

          dispatch({ type: "edit", payload: resData });
          resolve(resData);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
   const update = useCallback(
     async (id, data) => {
       return new Promise(async (resolve, reject) => {
         try {
           dispatch({ type: "status", payload: `loading` });
           const resData = await req(
             `category/${id}`,
             data,
             { method: "PATCH" },
             true
           );
           dispatch({ type: "status", payload: `idle` });
           dispatch({ type: "edit", payload: resData.data });
           resolve(resData);
         } catch (error) {
           console.log(error);
           reject(error);
         }
       });
     },
     [req]
   );

  const remove = useCallback(
    async (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `deleting ${id}` });
          const resData = await req(
            `category/${id}/`,
            {},
            { method: "DELETE" },
            true
          );
          dispatch({ type: "status", payload: "idle" });
          dispatch({ type: "delete", payload: id });
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        edit,
        create,
        update,
        dispatch,
        remove,
      }}
    >
      {children}
    </Context.Provider>
  );
}
