import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import Home from "./Home";
import TransferLog from "./TransferLog";
import Statistics from "./Statistics";

export default (
  <Route
    path="/"
    exact
    element={
      <RequireAuth>
        <Home />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <Statistics />
        </RequireAuth>
      }
    />
    <Route
      path="dashboard"
      element={
        <RequireAuth>
          <Statistics />
        </RequireAuth>
      }
    />
    <Route
      path="transferLog"
      element={
        <RequireAuth>
          <TransferLog />
        </RequireAuth>
      }
    />
  </Route>
);
