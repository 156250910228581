import styled, { css } from "styled-components";

export default styled.div.attrs((p) => ({
  delay: p.delay || 0.1,
}))`
  position: fixed;
  ${(p) =>
    p.x || p.y
      ? css`
          top: ${(p) => p.y}px;
          left: ${(p) => p.x}px;
        `
      : css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
  pointer-events: ${(p) => (p.show ? "all" : "none")};
  z-index: 99999;
  display: inline-block;
  white-space: nowrap;
  opacity: ${(p) => p.show};

  transition-property: transform, opacity;
  transition-duration: 0.06s !important;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-delay: ${(p) => (p.show ? p.delay : 0.02)}s;
  transform-origin: center center;
  transform: scale(${(p) => (p.show ? 1 : 0.7)})
    ${(p) => !p.x && "translate(-50%, -50%)"};
`;
