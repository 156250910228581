import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import Category from "./Category";
import CategoryList from "./CategoryList";

export default (
  <Route
    path="categories"
    exact
    element={
      <RequireAuth>
        <Category />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <CategoryList />
        </RequireAuth>
      }
    />
  </Route>
);
