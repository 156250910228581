import NetworkForm from "./NetworkForm";
import * as Yup from "yup";

// Schema
const schema = Yup.object().shape({
  networkName: Yup.string().required("this field is required"),
  currencySymbol: Yup.string().required("this field is required"),
  rpcURL: Yup.string().url().required("this field is required"),
  blockExplorerURL: Yup.string().url(),
  icon: Yup.string(),
  cryptoAddress: Yup.string(),
});
function UpdateNetwork({ setShow = () => {}, network = null }) {
  return <NetworkForm setShow={setShow} network={network} schema={schema} />;
}

export default UpdateNetwork;
