import UserAccount from "./UserAccount";
import { Flex } from "../../ui";
import ToggleSidebar from "./ToggleSidebar";

function Header({ setOpen, ...props }) {
  return (
    <Flex
      jc="space-between"
      gap={[20, 20, 20, 0]}
      {...props}
      sx={{ px: 20, py: 10, border: ["gray200", 1, "solid"] }}
    >
      <ToggleSidebar onClick={() => setOpen((v) => !v)} />
      <UserAccount />
    </Flex>
  );
}

export default Header;
