// React
import React, { useEffect, useState } from "react";
// UI
import {
  Box,
  Button,
  CardGrid,
  Flex,
  Icon,
  TableContainer,
  Type,
} from "../../ui";
import Dialog from "../../ui/Dialog";
// CSS
import "../../css/flags.css";
// Providers
import { useUsers } from "./Provider";
// Renders
import NameRender from "./CellRender/NameRender";
import OptionsRender from "./CellRender/OptionsRender";
import StatusRenderGrid from "./CellRender/StatusRenderGrid";
import VerifiedRender from "./CellRender/verifiedRender";
import TextRender from "./CellRender/TextRender";
// Forms
import AddUser from "./AddUser";
import { useUtils } from "../../services/utilsProvider";

const UsersList = () => {
  // Use States
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deletedId, setDeletedId] = useState(null);
  const [viewType, setViewType] = useState("list");
  // Providers
  const {
    state: { list: users },
    fetchList: fetchUsers,
    remove,
  } = useUsers();
  const { notify } = useUtils();
  const cols = [
    {
      label: "NAME",
      name: "name",
      render: NameRender,
      gridViewOnHeader: true,
    },
    {
      label: "EMAIL",
      name: "email",
      gridViewOnLabel: true,
      render: (row) => TextRender(row, "email"),
    },
    {
      label: "STATUS",
      name: "isActive",
      render: VerifiedRender,
      gridRender: StatusRenderGrid,
      gridViewOnLabel: true,
    },
    {
      label: "PHONE",
      name: "mobileNumber",
      gridViewOnLabel: true,
      render: (row) => TextRender(row, "mobileNumber"),
    },
  ];
  // Use Effects
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  // Handling
  const handleShowDelete = (flag, id) => {
    setShowDelete(flag);
    setDeletedId(id);
  };
  const handleDelete = async (id) => {
    try {
      await remove(id);
      setShowDelete(false);
      notify("User Deleted Successfully");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box data-testid="test-1">
      {/* Header With Icons */}
      <Flex jc="space-between" sx={{ mb: 15 }}>
        <Type as="h1" var="p" sx={{ fw: 600, fs: 30, c: "#303D4D", mb: 20 }}>
          Users <span style={{ color: "grey" }}>{`(${users?.length})`}</span>
        </Type>
        {/* View Buttons */}
        <Flex jc="space-around" sx={{ gap: 15 }}>
          <Icon
            name="DashboardIcon"
            w={16}
            c="gray300"
            hover="black"
            onClick={() => setViewType("grid")}
            circle
          />
          <Icon
            name="ListIcon"
            w={16}
            c="gray300"
            hover="black"
            onClick={() => setViewType("list")}
            circle
          />
        </Flex>
      </Flex>

      {/* Plus Icon */}
      <Flex jc="flex-end" sx={{ mb: 15 }}>
        <Icon
          name="Plus"
          w={16}
          c="secondary"
          hover={true}
          hoverBgc="secondary"
          hoverOpacity={0.8}
          onClick={() => setShow(true)}
          circle
        />
        <Dialog className="dialog" show={show} setShow={setShow}>
          <AddUser setShow={setShow} />
        </Dialog>
      </Flex>

      {/* Table */}
      {viewType === "list" ? (
        <TableContainer
          empty={users?.length === 0 ? true : false}
          data={users}
          cols={cols}
          tableProps={{ borderCollapse: "seperate", borderSpacing: " 0 8px" }}
          moreOptions={[
            {
              name: "delete",
              render: (row) => OptionsRender({ row, handleShowDelete }),
            },
          ]}
        />
      ) : (
        <CardGrid
          avatarName="avatarURL"
          iconName="user"
          data={users}
          empty={users?.length === 0 ? true : false}
          cols={cols}
          gridCols={"1fr 1fr 1fr"}
          gap={100}
          title={true}
          moreOptions={[
            {
              name: "delete",
              render: (row) => OptionsRender({ row, handleShowDelete }),
            },
          ]}
        />
      )}

      {/* Delete Dialog */}
      <Dialog className="dialog" show={showDelete} setShow={setShowDelete}>
        <Flex jc="center" ai="center" dir="column" gap="22">
          <Box>
            <Icon name="Trash" w={40} c="primary" />
          </Box>
          <Type sx={{ mt: 20, fs: 20 }}>
            Are you sure you want to delete this item?
          </Type>
          <Flex jc="center" ai="center" dir="column" gap="10">
            <Button
              sx={{ fs: 15, w: 400 }}
              onClick={() => handleDelete(deletedId)}
            >
              Delete
            </Button>
            <Button
              bg="grayButton"
              sx={{ c: "black", fs: 15, w: 400 }}
              onClick={() => setShowDelete(false)}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Dialog>
    </Box>
  );
};

export default UsersList;
