import { Box, Link } from "../../ui";
import Logo from "./Logo";
import Nav from "./Nav";

function Sidebar(props) {
  return (
    <Box {...props}>
      <Logo as={Link} to="/" sx={{w: 100}} />
      <Nav />
    </Box>
  );
}

export default Sidebar;
