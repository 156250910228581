import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";
import USDT from "./USDT";
import USDTList from "./USDTList";
import USDTAdd from "./USDTAdd";
import USDTUpdate from "./USDTUpdate";
import USDTDetail from "./USDTDetail";

export default (
  <Route
    path="usdt"
    exact
    element={
      <RequireAuth>
        <USDT />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <USDTList />
        </RequireAuth>
      }
    />
    <Route
      path="new"
      element={
        <RequireAuth>
          <USDTAdd />
        </RequireAuth>
      }
    />
    <Route
      path="update"
      element={
        <RequireAuth>
          <USDTUpdate />
        </RequireAuth>
      }
    />
    <Route
      path="detail/:id"
      element={
        <RequireAuth>
          <USDTDetail />
        </RequireAuth>
      }
    />
  </Route>
);
