import styled from "styled-components";

export default styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color[props.c || "gray200"]};
  transition: all 0.1s ease-in;
  outline: ${(p) => p.theme.color.white} 4px solid;
  img {
    width: 100%;
  }
  &:hover {
    background-color: ${(props) => props.theme.color[props.c || "gray300"]};
    outline: ${(p) => p.theme.color.gray100} 4px solid;
  }
`;
