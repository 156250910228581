import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import { Box } from "../../ui";

function Logo(props) {
  return (
    <Box
      as={Link}
      to="/"
      {...props}
      sx={{ w: [140, 140, 140, 120], m: 20, d: "block" }}
    >
      <img src={logo} alt="" style={{ width: "100%" }} />
    </Box>
  );
}

export default Logo;
