import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Providers from "./services/providers";
import Login from "./views/login/Login";
import ForgotPassword from "./views/login/forgotPassword/ForgotPassword";
import GlobalStyle from "./GlobalStyles";
import CompanyRoutes from "./views/Companies/routes";
import CategoryRoutes from "./views/Categories/routes";
import CountryRoutes from "./views/Country/routes";
import UsersRoutes from "./views/Users/routes";
import NetworksRoutes from "./views/Networks/routes";
import ClientsRoutes from "./views/Clients/routes";
import USDTRoutes from "./views/USDT/routes";
import ProfileDetail from "./views/profile/ProfileDetail";
import HomeRoutes from "./views/home/routes";

// Sentry.init({
//   dsn: "https://b441926ec0244c91960b380b7c4e32b3@o4504524571738112.ingest.sentry.io/4504525574569984",
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Providers>
    <GlobalStyle />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<ProfileDetail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {HomeRoutes}
          {CompanyRoutes}
          {CategoryRoutes}
          {CountryRoutes}
          {UsersRoutes}
          {NetworksRoutes}
          {ClientsRoutes}
          {USDTRoutes}
        </Route>
      </Routes>
    </BrowserRouter>
  </Providers>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
