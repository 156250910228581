import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Pagination,
  Select,
  TableContainer,
  Type,
} from "../../ui";
import Dialog from "../../ui/Dialog";
import Avatar from "../../ui/Avatar";
import ErrorsList from "../../ui/ErrorsList";
import { useCountries } from "./Provider";
import { useUtils } from "../../services/utilsProvider";
import CountryAdd from "./CountryAdd";
import CountryUpdate from "./CountryUpdate";

const CountryList = () => {
  const { notify } = useUtils();

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [error, setError] = useState({ title: "" });
  const [showDelete, setShowDelete] = useState(false);
  const [deletedId, setDeletedId] = useState(0);
  const [Id, setID] = useState(0);
  const [reload, setReload] = useState(false);

  const {
    state: { list: countries },
    fetchList: fetchCountries,
    remove,
  } = useCountries();
  const handleIconDelete = (ID) => {
    setDeletedId(ID);
    setShowDelete(true);
  };
  const handleUpdate = (ID) => {
    setID(ID);
  };
  const handleDelete = async (ID) => {
    setError(null);
    try {
      await remove(ID);
      notify("a country has been removed successfully.", "success");
      setShowDelete(false);
    } catch (error) {
      console.log(error);
      notify(error.errors.message, "alert");
    }
  };
  useEffect(() => {
    fetchCountries();
  }, [fetchCountries, reload]);
  const cols = [
    { label: "COUNTRY ID", name: "id" },
    { label: "COUNTRY", name: "country" },
  ];

  return (
    <>
      <Type as="h1" var="p" sx={{ fw: 600, fs: 30, c: "#303D4D", mb: 20 }}>
        Countries
      </Type>
      {error && (
        <Box sx={{ mb: 10 }}>
          <ErrorsList error={error} />
        </Box>
      )}

      <Flex jc="space-between">
        <Flex c="space-between" sx={{ mr: 50 }}>
          <Type var="Label" sx={{ pr: 10, ta: "center", mt: 10 }}>
            Show
          </Type>
          <Select
            data={[10, 50, 100]}
            inputStyle={{ width: "95px" }}
            size="medium"
            name="10"
          />
        </Flex>
        <Icon
          name="Plus"
          w={16}
          c="secondary"
          hover={true}
          hoverBgc="secondary"
          hoverOpacity={0.8}
          onClick={() => setShow(true)}
          circle
        />
        <Dialog className="dialog" show={show} setShow={setShow}>
          <CountryAdd show={show} setShow={setShow} />
        </Dialog>
      </Flex>

      <Box>
        <TableContainer
          empty={countries?.length === 0 ? true : false}
          data={countries}
          cols={cols}
          moreOptions={[
            {
              name: "Update",
              render: (row) => (
                <Avatar
                  className="avatar"
                  onClick={() => {
                    handleUpdate(row?.id);
                    setShow2(true);
                  }}
                  style={{ marginBottom: "15px" }}
                >
                  <Icon name="Edit" w={16} c="black" />
                </Avatar>
              ),
            },
            {
              name: "delete",
              render: (row) => (
                <Box>
                  <Avatar onClick={() => handleIconDelete(row?.id)}>
                    <Icon name="Trash" w={16} c="primary" />
                  </Avatar>
                </Box>
              ),
            },
          ]}
        />
      </Box>
      <Flex jc="end" ai="flex-top" sx={{ mb: 60 }}>
        <Box>
          <Pagination
            count={10}
            page={1}
            onPageChange={() => console.log("page")}
          />
        </Box>
      </Flex>
      {/* Delete Dialog */}
      <Dialog className="dialog" show={showDelete} setShow={setShowDelete}>
        <Flex jc="center" ai="center" dir="column" gap="22">
          <Box>
            <Icon name="Trash" w={40} c="primary" />
          </Box>
          <Type sx={{ mt: 20, fs: 20 }}>
            Are you sure you want to delete this item?
          </Type>
          <Flex jc="center" ai="center" dir="column" gap="10">
            <Button
              sx={{ fs: 15, w: 400 }}
              onClick={() => handleDelete(deletedId)}
            >
              Delete
            </Button>
            <Button
              bg="grayButton"
              sx={{ c: "black", fs: 15, w: 400 }}
              onClick={() => setShowDelete(false)}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </Dialog>
      {/*update Dialog */}
      <Dialog className="dialog" show={show2} setShow={setShow2}>
        <CountryUpdate
          show={show2}
          setShow={setShow2}
          Id={Id}
          reload={reload}
          setReload={setReload}
        />
      </Dialog>
    </>
  );
};

export default CountryList;
