import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LayoutMasked from "../../layout/LayoutMasked";
import { useAuth } from "../../services/auth";
import LoginForm from "./LoginForm";
import { Box, Flex, Grid, Type } from "../../ui";
import logo from "../../assets/logo.png";
import Polygon from "../../assets/Your3Dimage@2x.png";
import Shadow from "../../assets/shapecolor@2x.png";

function Login() {
  const navigate = useNavigate();
  let location = useLocation();
  const { user, isLoggedIn } = useAuth();

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  useEffect(() => {
    if (isLoggedIn()) navigate(from, { replace: true });
  }, [isLoggedIn, from, navigate]);

  const handleSuccess = () => {
    navigate(from, { replace: true });
  };

  return (
    <LayoutMasked
      title={`Welcome Back ${user ? user?.firstName : ""}`}
      onClose={() => navigate("/")}
    >
      <Grid cols="60% 40%" gap={0} sx={{ h: "100%" }}>
        <Flex sx={{ bgc: "gray200", h: "100%" }}>
          <Box sx={{ w: "100%" }}>
            <Flex sx={{ mb: 100 }}>
              <img
                src={Shadow}
                alt="Shadow"
                width="620"
                style={{ position: "absolute", zIndex: "1" }}
              />
              <img
                src={Polygon}
                alt="Polygon"
                width="300"
                style={{
                  position: "relative",
                  zIndex: "10",
                }}
              />
            </Flex>
            <Box sx={{ pl: 60, p: "absolute", fs: "50px" }}>
              <Type var="h1" as="h1" sx={{ fs: "50px", mb: 30 }}>
                Welcome to
              </Type>
              <Type var="h1" as="h1" sx={{ c: "redSystem", fs: "50px" }}>
                {" "}
                Admin Panel
              </Type>
            </Box>
          </Box>
        </Flex>
        <Flex dir="column" gap={30} sx={{ m: 30 }}>
          <img src={logo} alt="Stitvest" width="216" />
          <Type var="h4" as="h4" sx={{ mb: 15 }}>
            Login to your Account
          </Type>
          <LoginForm onSuccess={handleSuccess} />
        </Flex>
      </Grid>
    </LayoutMasked>
  );
}

export default Login;
