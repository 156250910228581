import NetworkForm from "./NetworkForm";
import * as Yup from "yup";

const schema = Yup.object().shape({
  chainID: Yup.string().required("this field is required"),
  networkName: Yup.string().required("this field is required"),
  currencySymbol: Yup.string().required("this field is required"),
  rpcURL: Yup.string().url().required("this field is required"),
  blockExplorerURL: Yup.string().url(),
  icon: Yup.string(),
  cryptoAddress: Yup.string(),
});

function AddNetwork({ setShow = () => {} }) {
  return <NetworkForm setShow={setShow} schema={schema} />;
}

export default AddNetwork;
