// React
import React from "react";
// UI
import { Type, Flex, Icon } from "../../../ui";
import Avatar from "../../../ui/Avatar";

const StatusRender = (row) => {
  return (
    <Flex jc="flex-start" sx={{ mw: 300, of: "hidden" }}>
      {/* Avatar circle */}
      <Avatar className="avatar">
        {row?.icon ? (
          <img src={row?.icon} alt="user profile avatar" />
        ) : (
          <Icon name="user" w={16} c="gray600" />
        )}
      </Avatar>
      {/* Name */}
      <Type sx={{ ml: 10 }}>
        {row?.networkName}
        {/* Regina Cooper */}
      </Type>
    </Flex>
  );
};

export default StatusRender;
