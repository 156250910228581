// React
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Layout
import LayoutMasked from "../../../layout/LayoutMasked";
// Auth
import { useAuth } from "../../../services/auth";
// Components
import ForgotForm from "./ForgotForm";
// UI
import { Flex, Grid, Type } from "../../../ui";
// Assets
import logo from "../../../assets/logo.png";
import Polygon from "../../../assets/Your3Dimage@2x.png";
import Shadow from "../../../assets/shapecolor@2x.png";

function ForgotPassword() {
  // Hooks
  const navigate = useNavigate();
  let location = useLocation();
  // Auth
  const { user, isLoggedIn } = useAuth();

  const paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);

  let from =
    location.state?.from?.pathname ||
    (searchParams.has("from") && searchParams.get("from")) ||
    "/";

  useEffect(() => {
    if (isLoggedIn()) navigate(from, { replace: true });
  }, [isLoggedIn, from, navigate]);

  const handleSuccess = () => {
    navigate(from, { replace: true });
  };

  return (
    <LayoutMasked
      title={`Welcome Back ${user ? user?.firstName : ""}`}
      onClose={() => navigate("/")}
    >
      <Grid cols="60% 40%" gap={0} sx={{ h: "100%" }}>
        <Flex sx={{ bgc: "gray200", h: "100%" }}>
          <Type var="h1" as="h1">
            <Flex sx={{ mb: 100 }}>
              <img
                src={Shadow}
                alt="Shadow"
                width="620"
                style={{ position: "absolute", zIndex: "1" }}
              />
              <img
                src={Polygon}
                alt="Polygon"
                width="300"
                style={{ position: "relative", zIndex: "10" }}
              />
            </Flex>
          </Type>
        </Flex>
        <Flex dir="column" gap={30} sx={{ m: 30 }}>
          <img src={logo} alt="Stitvest" width="216" />
          <Type var="h4" as="h4" sx={{ mb: 15 }}>
            Forgot Password?
          </Type>
          <ForgotForm onSuccess={handleSuccess} />
        </Flex>
      </Grid>
    </LayoutMasked>
  );
}

export default ForgotPassword;
