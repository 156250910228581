import { useContext, useReducer, createContext, useCallback } from "react";
import { useAuth } from "../../services/auth";
import { useRequest } from "../../services/request";
import { createThumb } from "../../utilities/functions";
import { useRef } from "react";

const Context = createContext();

export function useProfile() {
  return useContext(Context);
}

const initState = {
  selected: null,
  status: "idle",
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_selected":
      if (action.keep && state.selected) return { ...state };
      return { ...state, selected: { ...action.payload } };
    case "set_wallet":
      return {
        ...state,
        selected: {
          ...state.selected,
          walletAddress: action.payload,
        },
      };
    case "set_avatar":
      return {
        ...state,
        selected: {
          ...state.selected,
          user: { ...state.selected.user, avatarURL: action.payload },
        },
      };
    case "status":
      return { ...state, status: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function ProfileProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();
  const { setVerified, signout } = useAuth();

  let isFetchingOne = useRef(false);

  // const fetchOne = useCallback(
  //   (id, force = false) => {
  //     return new Promise(async (resolve, reject) => {
  //       if (
  //         isFetchingOne.current ||
  //         (state.selected &&
  //           Number(state.selected?.id) === Number(id) &&
  //           !force)
  //       ) {
  //         resolve(state.selected);
  //         return;
  //       }

  //       isFetchingOne.current = true;
  //       dispatch({ type: "status", payload: `fetching` });
  //       try {
  //         const resData = await req(`account/profile/${id}/`, null, {}, true);
  //         resData.data.dateOfBirth = resData.data.dateOfBirth?.split("T")[0];
  //         dispatch({ type: "set_selected", payload: resData.data });
  //         setVerified(resData.data.isVerified);
  //         resolve(resData.data);
  //       } catch (e) {
  //         reject(e);
  //         // signout();
  //         // if (e.errors.message === "INVALID_USER")
  //         //   window.location.href = "/login";
  //       } finally {
  //         dispatch({ type: "status", payload: `idle` });
  //       }
  //     });
  //   },
  //   [req, setVerified, state]
  // );

  const fetchOne = useCallback(
    (id) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `fetching` });
        try {
          const resData = await req(`auth/profile/${id}/`, null, {}, true);
          dispatch({ type: "set_selected", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );
  const update = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        dispatch({ type: "status", payload: `updating` });
        try {
          const resData = await req(
            `account/update/`,
            data,
            { method: "PATCH" },
            true
          );
          resData.data.dateOfBirth = resData.data.dateOfBirth?.split("T")[0];
          dispatch({ type: "set_selected", payload: resData.data });
          resolve(resData.data);
        } catch (e) {
          reject(e);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  const saveAvatar = useCallback(
    (file) => {
      return new Promise(async (resolve, reject) => {
        try {
          dispatch({ type: "status", payload: `loading` });
          const thumb = await createThumb(file, 500, 500);

          let formData = new FormData();
          formData.append("avatar", thumb);

          const resData = await req(
            "account/setAvatar/",
            formData,
            { method: "POST", headers: {} },
            true
          );
          dispatch({ type: "set_avatar", payload: resData.data.avatarURL });
          resolve(resData.data);
        } catch (error) {
          console.log(error);
          reject(error);
        } finally {
          dispatch({ type: "status", payload: `idle` });
        }
      });
    },
    [req]
  );

  let setWallet = useCallback(
    (address) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `account/setWallet/`,
            { walletAddress: address },
            {
              method: "PATCH",
            },
            true
          );
          dispatch({ type: "set_wallet", payload: address });
          resolve(res.data);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let resetWallet = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await req(
          `account/resetWallet/`,
          null,
          {
            method: "PATCH",
          },
          true
        );
        dispatch({ type: "set_wallet", payload: null });
        resolve(res.data);
      } catch (error) {
        reject(error);
        console.log(error);
      }
    });
  }, [req]);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchOne,
        setWallet,
        update,
        saveAvatar,
        resetWallet,
      }}
    >
      {children}
    </Context.Provider>
  );
}
