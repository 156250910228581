import React from "react";
const StatusRender = (row) => {
  var status;
  var bgc;
  var c;
  switch (row.status) {
    case 0:
      {
        status = "DRAFT";
        bgc = "#FBDADC";
        c = "#CE3247";
      }
      break;
    case 1:
      {
        status = "Comming Soon";
        bgc = "#EFFBF9";
        c = "#7CDECE";
      }
      break;
    case 2:
      {
        status = "UPCOMING";
        bgc = "#F1DCF9";
        c = "#C773E7";
      }
      break;
    case 3:
      {
        status = "Open";
        bgc = "#E1F0D1";
        c = "#A5D274";
      }
      break;
    case 4:
      {
        status = "CLOSED";
        bgc = "#FBDADC";
        c = "#CE3247";
      }
      break;

    default:
      break;
  }

  return (
    <div
      style={{
        backgroundColor: bgc,
        borderRadius: "8px",
        width: "180px",
        height: "30px",
        padding: "5px",
        textAlign: "center",
        color: c,
        fontSize: "15px",
      }}
    >
      {status}
    </div>
  );
};

export default StatusRender;
