import { ThemeProvider } from "styled-components";
import AuthProvider from "./auth";
import UtilsProvider from "./utilsProvider";
import theme from "../theme";
import ProfileProvider from "../views/profile/provider";
import CompanyProvider from "../views/Companies/Provider";
import CategoryProvider from "../views/Categories/Provider";
import UserProvider from "../views/Users/Provider";
import NetworkProvider from "../views/Networks/Provider";
import ClientProvider from "../views/Clients/Provider";
import CountryProvider from "../views/Country/Provider";
import USDTProvider from "../views/USDT/Provider";
import DashboardStatisticsProvider from "../views/home/Provider";

export default function Providers({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <UtilsProvider>
        <AuthProvider>
          <ProfileProvider>
            <CategoryProvider>
              <CompanyProvider>
                <UserProvider>
                  <NetworkProvider>
                    <ClientProvider>
                      <CountryProvider>
                        <USDTProvider>
                          <DashboardStatisticsProvider>
                            {children}
                          </DashboardStatisticsProvider>
                        </USDTProvider>
                      </CountryProvider>
                    </ClientProvider>
                  </NetworkProvider>
                </UserProvider>
              </CompanyProvider>
            </CategoryProvider>
          </ProfileProvider>
        </AuthProvider>
      </UtilsProvider>
    </ThemeProvider>
  );
}
