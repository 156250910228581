import React, { useEffect } from "react";
import { useUtils } from "../../services/utilsProvider";
import { Type } from "../../ui";
import { useCountries } from "./Provider";
import CountryForm from "./CountryForm";

const CountryUpdate = ({ show, setShow, Id, reload, setReload }) => {
  const { notify } = useUtils();
  const {
    state: { selected: country },
    fetchOne: fetchCountry,
  } = useCountries();

  useEffect(() => {
    fetchCountry(Id);
  }, [fetchCountry, country]);

  const handleSuccess = () => {
    notify("a country has been updated successfully.");
    setReload(!reload);

    setShow(false);
  };
  if (!country) return;
  if (country?.id !== Id) return;

  return (
    <div>
      <Type as="h1" var="Heading" sx={{ mb: 40 }}>
        Update Country
      </Type>
      <CountryForm country={country} handleSuccess={handleSuccess} Id={Id} />
    </div>
  );
};

export default CountryUpdate;
