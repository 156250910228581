import { useContext, useReducer, createContext, useCallback } from "react";
import { useRequest } from "../../services/request";

const Context = createContext();

export function useDashboardStatistics() {
  return useContext(Context);
}

const initState = {
  list: [],
  logs: [],
  selected: null,
  selectedWallet: null,
  count: 0,
  page: 0,
  perPage: 10,
  status: "idle",
  error: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_list":
      return { ...state, list: [...action.payload] };
    case "set_logs":
      return { ...state, logs: [...action.payload] };
    case "set_selected":
      return { ...state, selected: { ...action.payload } };
    case "set_selectedWallet":
      return { ...state, selectedWallet: { ...action.payload } };
    case "set_count":
      return { ...state, count: action.payload };
    case "add":
      const newList = [{ ...action.payload }, ...state.list];
      return { ...state, list: newList };
    case "edit":
      const modified = state.list.map((p) =>
        p.id === action.payload.id ? { ...p, ...action.payload } : p
      );
      return { ...state, list: modified };
    case "delete":
      const filtered = state.list.filter((p) => p.id !== action.payload);
      return { ...state, list: filtered };
    case "status":
      return { ...state, status: action.payload };
    case "set_page":
      return { ...state, page: action.payload };
    case "set_per_page":
      return { ...state, perPage: action.payload };

    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function DashboardStatisticsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initState);
  const req = useRequest();

  const fetchList = useCallback(async () => {
    try {
      dispatch({ type: "status", payload: "fetching" });
      const resData = await req(
        "statistics/dashboard/homePage",
        null,
        {},
        true
      );

      dispatch({ type: "set_selected", payload: resData.data || [] });
      dispatch({ type: "status", payload: `idle` });
    } catch (e) {
      console.log(e, "eee");
    }
  }, [req]);
  const fetchWallet = useCallback(
    async (wallet) => {
      dispatch({ type: "status", payload: "fetching" });
      let query = `wallet=${wallet}`;
      const resData = await req(
        `statistics/investment/live/{wallet}?${query}`,
        null,
        {},
        true
      );
      dispatch({ type: "status", payload: `idle` });
      dispatch({ type: "set_list", payload: resData.data });
    },

    [req]
  );
  const fetchLogs = useCallback(
    async (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const newData = { ...data };

          const resData = await req(
            "statistics/directTransferLogs",
            newData,
            { method: "POST" },
            true
          );

          dispatch({ type: "set_logs", payload: resData.data || [] });
          dispatch({ type: "status", payload: `idle` });
          resolve(resData);
        } catch (e) {
          console.log(e, "eee");
          reject(e);
        }
      });
    },
    [req]
  );
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        fetchList,
        fetchWallet,
        fetchLogs,
      }}
    >
      {children}
    </Context.Provider>
  );
}
