// React
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
// Providers
import { useNetworks } from "./Provider";
// UI
import { Box, Button, Flex, Icon, InputText, Select, Type } from "../../ui";
// Fields
import fields from "./fields";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUtils } from "../../services/utilsProvider";
import Label from "../../ui/Label";
// // Schema
// const schema = Yup.object().shape({
//   chainID: Yup.string().required("this field is required"),
//   networkName: Yup.string().required("this field is required"),
//   currencySymbol: Yup.string().required("this field is required"),
//   rpcURL: Yup.string().required("this field is required"),
//   blockExplorerURL: Yup.string(),
//   icon: Yup.string(),
// });

const NetworkForm = ({ setShow, network, schema }) => {
  // Use Form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // Providers
  const {
    state: { avatarURL },
    create,
    update,
    uploadAvatar,
  } = useNetworks();
  const { notify } = useUtils();
  // Use State
  const [image, setimage] = useState("");
  const [error, setError] = useState(null);
  const mapUsedLibrary = { label: "libraryName", value: "libraryValue" };

  const onSubmit = async (values) => {
    network && !avatarURL
      ? (values.icon = network.icon)
      : (values.icon = avatarURL);
    // values.chainID = Number(JSON.parse(
    //   localStorage.getItem("blockchainData")
    // )?.chainID);
    !network && (values.chainID = Number(values.chainID));
    delete values.avatarURL;
    try {
      if (network) await update(network?.chainID, values);
      else await create(values);
      setShow(false);
      if (network) notify("Network Updated Successfully");
      else notify("Network Added Successfully");
    } catch (error) {
      if (error.status === 400) {
        if (error.errors.response === 21) setError("Network Already Exists");
        if (error.errors.response === 16) setError("Invalid Request");
        else setError("Error in data entered");
        // else setError("Username or Password are invalid");
      } else if (error.status === 409) {
        if (error.errors.response === 90) setError("Chain ID Already Exists");
      }
    }
  };

  const fileSelectedHandler1 = async () => {
    var avatar = document.querySelector("#file1").files[0];
    setimage(URL.createObjectURL(avatar));
    try {
      await uploadAvatar(avatar);
      setValue("avatarURL", avatarURL);
    } catch (e) {
      setError("Error Uploading Image");
    }
  };
  const onChangeLibrary = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );

  // Getters
  const getData = (name) => {
    switch (name) {
      case "usedLibrary":
        return [
          { libraryName: "web3", libraryValue: "web3" },
          { libraryName: "tronweb", libraryValue: "tronweb" },
        ];
    }
  };

  const getMapData = (mapData) => {
    switch (mapData) {
      case "mapUsedLibrary":
        return mapUsedLibrary;
    }
  };
  return (
    <>
      {/* Header */}
      <Flex jc="space-between" sx={{ mb: 20 }}>
        <Type as="h1" sx={{ fw: 600, fs: 28, mb: 20 }}>
          {network ? "Update Network " : "New Network"}
          {/* New Network */}
        </Type>
      </Flex>
      {error && (
        <Type data-testid="err" sx={{ c: "primary", mb: 10 }}>
          {error}
        </Type>
      )}
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        data-testid="formBox"
      >
        {/* Form */}
        <Flex dir="column" sx={{ w: 400 }}>
          {/* Avatar */}
          <label style={{ cursor: "pointer" }}>
            <input
              style={{ display: "none" }}
              id="file1"
              accept=".png, .jpg, .jpeg"
              type="file"
              onChange={fileSelectedHandler1}
              inputProps={{
                type: "file",
                ...register("avatarURL"),
                autoFocus: true,
              }}
            />
            {image ? (
              <img
                src={image}
                alt="avatar"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "10px",
                }}
              />
            ) : network?.icon ? (
              <img
                src={network?.icon}
                alt="avatar"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <Flex
                sx={{
                  border: ["gray500", "1px", "dashed", "8px"],
                  py: 40,
                  px: 40,
                  cursor: "pointer",
                }}
              >
                <Icon name="Plus" w={30} c="gray600" />
              </Flex>
            )}
          </label>
          <Box
            // Scroll
            sx={{
              w: "100%",
              h: 400,
              of: "auto",
              m: "20px",
              p: "10px",
            }}
            style={{
              boxShadow: "3px 10px 10px #00000018",
            }}
          >
            {fields?.map((f, index) =>
              f?.label === "Chain ID" && network ? (
                <div key={index}></div>
              ) : f?.select ? (
                <>
                  <Label>
                    {f.label}
                    {f?.required && "*"}
                  </Label>
                  <Select
                    error={!!errors[f?.name]}
                    onChange={onChangeLibrary}
                    data={getData(f?.data)}
                    mapData={getMapData(f?.mapData)}
                    inputStyle={{ width: "380px" }}
                    size="medium"
                    defaultValue={network && network?.usedLibrary}
                    inputProps={{
                      ...register(f?.name),
                    }}
                    name={`Select ${f?.label}`}
                  />
                </>
              ) : (
                <InputText
                  label={f?.required ? f?.label + " *" : f?.label}
                  name={f?.name}
                  type={f?.type}
                  data-testid={f?.name}
                  // defaultValue={network && network[f?.name]}
                  key={index}
                  error={!!errors[f?.name]}
                  msg={errors[f?.name]?.message}
                  style={{ width: "100%" }}
                  inputProps={{
                    ...register(f?.name),
                    "aria-label": f?.name,
                    defaultValue: network && network[f?.name],
                  }}
                />
              )
            )}
          </Box>
          <Button
            type="submit"
            bg="secondary"
            name="create"
            data-testid="create"
            sx={{ fs: "15px", w: 400, br: "8px" }}
          >
            {network ? "Update " : "Add Network"}
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default NetworkForm;
