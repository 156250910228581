import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  InputText,
  Pagination,
  Select,
  TableContainer,
  Type,
} from "../../ui";
import "../../css/flags.css";
import { useNavigate } from "react-router-dom";
import { useCompany } from "./Provider";
import { useCategories } from "../Categories/Provider";
import CategoryRender from "./render/CategoryRender";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import StatusRender from "./render/StatusRender";
import IconButton from "../../ui/IconButton";
import Dialog from "../../ui/Dialog";
import UpdateStatus from "./UpdateStatus";
import { useUtils } from "../../services/utilsProvider";

const schema = Yup.object().shape({
  categoryID: Yup.number(),
  statusID: Yup.number(),
  isFeatured: Yup.bool(),
});

const CompanyList = () => {
  let navigate = useNavigate();
  const { notify } = useUtils();

  const [Show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [id, setId] = useState(0);
  const [status, setStatus] = useState([
    { id: undefined, name: "All Status" },
    { id: 0, name: "DRAFT" },
    { id: 1, name: "COMING SOON" },
    { id: 2, name: "UPCOMING" },
    { id: 3, name: "OPEN" },
    { id: 4, name: "CLOSED" },
  ]);
  const mapData = { label: "categoryName", value: "id" };
  const mapDataStatus = { label: "name", value: "id" };

  const {
    state: { list: companies },
    fetchList: fetchCompany,
  } = useCompany();
  const {
    state: { list: categories },
    fetchList: fetchCategories,
  } = useCategories();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany, reload]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeStatus = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );
  const onChangeCategory = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );
  const handleSuccess = () => {
    notify("Status updated successfully" + "success");
    setShow(false);
    setReload(!reload);
  };
  const onSubmit = async (values) => {
    try {
      await fetchCompany(values);
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("e", e);
    }
  };
  const cols = [
    { label: "ID", name: "id" },
    { label: "COMPANY NAME", name: "companyName" },
    { label: "COMPANY WEBSITE", name: "website" },
    { label: "CATEGORY", name: "categories", render: CategoryRender },
    { label: "STATUS", name: "status", render: StatusRender },
  ];

  if (!companies || !categories) return;

  return (
    <>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Type as="h1" var="Title" sx={{ mb: 25 }}>
          Companies
        </Type>

        <Flex jc="space-between" sx={{ mb: 20 }}>
          <Flex c="space-between">
            <Type var="Label" sx={{ pr: 10, ta: "center", mt: 10 }}>
              Show
            </Type>
            <Select
              data={[10, 50, 100]}
              inputStyle={{ width: "95px" }}
              size="medium"
              name="10"
            />
          </Flex>
          <Select
            onChange={onChangeCategory}
            data={[
              { id: undefined, categoryName: "All Categories" },
              ...categories,
            ]}
            mapData={mapData}
            inputStyle={{ width: "240px" }}
            name="Select Category"
            size="medium"
            inputProps={{
              ...register("categoryID"),
            }}
          />
          <Select
            onChange={onChangeStatus}
            mapData={mapDataStatus}
            data={status}
            inputStyle={{ width: "185px" }}
            size="medium"
            name="Select Status"
            inputProps={{
              ...register("statusID"),
            }}
          />
          <InputText
            style={{
              width: "450px",
              marginTop: "20px",
            }}
            size="large"
            placeholder="Search Company..."
            type="Search"
          />
          <Checkbox
            id="isFeatured"
            inputProps={{
              ...register("isFeatured"),
            }}
            label="Featured"
            sx={{ mb: 20 }}
            size="small"
          />
          <IconButton
            loading={isSubmitting}
            type="submit"
            className="small"
            bg="secondary"
          >
            <Icon name="filter" />
          </IconButton>
        </Flex>

        <TableContainer
          empty={companies?.length == 0 ? true : false}
          data={companies}
          cols={cols}
          showButton={{
            label: "ACTION",
            render: (e) => navigate("detail/" + e.id),
            Icon: "Detail",
          }}
          moreOptions={[
            {
              name: "set Status",
              render: (row) =>
                (row.status === 1 && (
                  <Box>
                    <Button
                      variant="text"
                      bg="gray900"
                      onClick={() => {
                        setShow(true);
                        setId(row?.id);
                      }}
                    >
                      Update Status
                    </Button>
                  </Box>
                )) ||
                (row.status === 0 && (
                  <Box>
                    <Button
                      variant="text"
                      bg="gray900"
                      onClick={() => {
                        setShow(true);
                        setId(row?.id);
                      }}
                    >
                      Update Status
                    </Button>
                  </Box>
                )),
            },
            {
              name: "Image Slider",
              render: (row) => (
                <Box>
                  <Button
                    variant="text"
                    bg="gray900"
                    onClick={() => navigate("image-slider/" + row?.id)}
                  >
                    Image Slider
                  </Button>
                </Box>
              ),
            },
            {
              name: "Documents",
              render: (row) => (
                <Button
                  variant="text"
                  bg="gray900"
                  onClick={() => navigate("documents/" + row?.id)}
                >
                  Documents
                </Button>
              ),
            },
          ]}
        />

        <Flex jc="end" ai="flex-top" sx={{ mb: 60 }}>
          <Box>
            <Pagination
              count={10}
              page={1}
              onPageChange={() => console.log("page")}
            />
          </Box>
        </Flex>
      </Box>
      <Dialog className="dialog" show={Show} setShow={setShow}>
        <UpdateStatus
          setShow={setShow}
          id={id}
          reload={reload}
          setReload={setReload}
          handleSuccess={handleSuccess}
        />
      </Dialog>
    </>
  );
};

export default React.memo(CompanyList);
