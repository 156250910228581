import React, { useState } from "react";
import { Box, Button, Flex, Grid, InputText, Type } from "../../ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import fields from "./fields";
import { toUtc, tolocalDate } from "../../utilities/functions";
import { useCompany } from "./Provider";

const CompanyUpdate = ({ company, user, onSuccess, setreload }) => {
  const [startDay, setStartDay] = useState(new Date());

  const { edit } = useCompany();

  const formSchema = Yup.object().shape({
    description: Yup.string(""),
    geographicMarket: Yup.string(),
    headquarter: Yup.string(),
    totalShares: Yup.number()
      .min(1, "Enter Num greater than 0")
      .integer("Enter Integer Num")
      .typeError("Enter Num greater than 0"),
    desiredTokenCap: Yup.number()
      .min(1, "Enter Num greater than 0")
      .integer("Enter Integer Num")
      .typeError("Enter Num greater than 0"),
    pricePerShare: Yup.number()
      .min(1, "Enter Num greater than 0")
      .integer("Enter Integer Num")
      .typeError("Enter Num greater than 0"),
    startDateTime: Yup.date("must be date")
      .typeError("The value must be a date (YYYY-MM-DD)")
      .min(startDay),
    launchDateTime: Yup.date()
      .typeError("The value must be a date (YYYY-MM-DD)")
      .when(
        "startDateTime",
        (startDateTime, schema) => startDateTime && schema.min(startDateTime)
      ),
    imageURL: Yup.string(),
    minPurchase: Yup.number()
      .min(1, "Enter Num greater than 0")
      .integer("Enter Integer Num")
      .typeError("Enter Num greater than 0"),
    purchaseLimit: Yup.number()
      .min(1, "Enter Num greater than 0")
      .integer("Enter Integer Num")
      .typeError("Enter Num greater than 0"),
    stitvestRate: Yup.number()
      .min(0, "Enter Num greater than 0")
      .typeError("Enter Num greater than 0"),
    targetWallet: Yup.string().required("Require Field"),
    rewardRate: Yup.number()
      .min(0, "Enter Num greater than 0")
      .typeError("must be number"),
    minStakingPeriod: Yup.number().typeError("must be number"),
  });

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (values) => {
    delete values.id;
    delete values.company;
    delete values.companyDocuments;
    delete values.companyName;
    delete values.status;
    delete values.statusDescription;
    delete values.website;
    delete values.crowdsaleAddress;
    delete values.tokenAddress;
    values.startDateTime
      ? (values.startDateTime = toUtc(values.startDateTime, true))
      : (values.startDateTime = company.startDateTime);
    values.launchDateTime
      ? (values.launchDateTime = toUtc(values.launchDateTime, true))
      : (values.launchDateTime = company.launchDateTime);

    try {
      await edit(company?.id, values);
      onSuccess();
      setreload(true);
    } catch (error) {
      window.scrollTo(0, 0);
      console.error(error, "error");
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Type var="Heading" sx={{ mb: 15 }}>
        Description
      </Type>
      <InputText
        style={{ marginRight: "5px" }}
        error={!!errors.description}
        msg={errors["description"]?.message}
        inputProps={{
          defaultValue: company?.description,
          ...register("description"),
          autoFocus: true,
        }}
      />
      <Grid cols="190px 270px 230px 270px" gap={0}>
        {fields.map((field, index) => {
          return (
            field.secondCard && (
              <>
                {" "}
                <Type var="Label" sx={{ mb: 15, fs: 15 }}>
                  {field.label} {field?.unit && `(${field?.unit})`}
                </Type>
                {!field.date && (
                  <InputText
                    key={index}
                    style={{ marginRight: "5px" }}
                    error={!!errors?.[field.name]}
                    msg={errors[field.name]?.message}
                    inputProps={{
                      defaultValue:
                        company?.[field.name] ?? company?.[field.name],
                      ...register(field.name),
                      autoFocus: true,
                    }}
                  />
                )}
                {field.date && (
                  <InputText
                    type="datetime-local"
                    error={!!errors?.[field.name]}
                    msg={errors[field.name]?.message}
                    key={index}
                    style={{ marginRight: "5px" }}
                    inputProps={{
                      defaultValue: tolocalDate(company?.[field.name]),
                      ...register(field.name),
                      autoFocus: true,
                    }}
                  />
                )}
              </>
            )
          );
        })}
      </Grid>
      <hr />
      <Box sx={{ mb: 20 }}>
        <Type var="Heading" sx={{ mb: 15, mt: 15 }}>
          Block Chain
        </Type>
        <Flex jc="flex-start">
          <Type var="Label" sx={{ fs: 15, mb: 15, mr: 50 }}>
            Network Name
          </Type>
          <Type sx={{ fs: 15, mb: 15 }}>
            {user?.tokens?.blockchainData?.networkName}
          </Type>
        </Flex>
        <Type var="Label" sx={{ fs: 15, mb: 15, mr: 50 }}>
          Target Wallet
        </Type>
        <InputText
          style={{ marginRight: "5px" }}
          error={!!errors.targetWallet}
          msg={errors?.targetWallet?.message}
          inputProps={{
            defaultValue: company?.targetWallet,
            ...register("targetWallet"),
            autoFocus: true,
          }}
        />
      </Box>
      <Button
        type="submit"
        bg="secondary"
        sx={{ mx: "auto", w: "100%", mt: 10 }}
        loading={isSubmitting}
      >
        Save
      </Button>
    </Box>
  );
};

export default CompanyUpdate;
