import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, InputText } from "../../ui";
import { useCountries } from "./Provider";
import ErrorsList from "../../ui/ErrorsList";

const CountryForm = ({ country = null, handleSuccess }) => {
  const [error, setError] = useState(null);

  const formSchema = Yup.object().shape({
    country: Yup.string().required("Required"),
  });
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { create, edit } = useCountries();
  const onSubmit = async (values) => {
    delete values.id;
    try {
      if (country) await edit(country?.id, values);
      else {
        await create(values);
      }
      handleSuccess();
    } catch (e) {
      setError({ title: e.errors.message });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {error && (
        <Box sx={{ mb: "40px" }}>
          <ErrorsList error={error} />{" "}
        </Box>
      )}
      <InputText
        label="Country"
        name="country"
        type="text"
        data-testid="country"
        error={!!errors["country"]}
        msg={errors["country"]?.message}
        style={{
          width: "100%",
          marginBottom: "40px",
        }}
        inputProps={{
          defaultValue: country?.country,
          ...register("country"),
          "aria-label": "country",
        }}
      />
      <Button
        type="submit"
        bg="secondary"
        name="create"
        data-testid="create"
        sx={{ fs: "15px", w: 400, br: "8px", mt: "40px" }}
      >
        {country ? <>Update</> : <>Add</>} Country
      </Button>
    </form>
  );
};

export default CountryForm;
