import React, { useEffect } from "react";
import { Box, Card, Flex, Grid, Type } from "../../ui";
import { useUSDT } from "./Provider";
import { useParams } from "react-router-dom";
import { useCompany } from "../Companies/Provider";

const USDTDetail = () => {
  const { id } = useParams();
  const {
    state: { selected: usdt },
    fetchOne: fetchUSDT,
  } = useUSDT();

  useEffect(() => {
    fetchUSDT(id);
  }, [fetchUSDT, id]);
  const {
    state: { selected: company },
    fetchOne: fetchCompany,
  } = useCompany();

  useEffect(() => {
    if (usdt) fetchCompany(usdt?.companyID);
  }, [fetchCompany, id]);
  if (!usdt) return;
  return (
    <>
      {" "}
      <Type as="h1" var="Heading" sx={{ mb: 40 }}>
        USDT Details
      </Type>
      <Grid cols="350px 700px" gap={20}>
        <Card sx={{ w: 280 }}>
          <Box sx={{ pt: "20px", mt: "20px" }}>
            <Flex jc="space-between" sx={{ mt: "20px" }}>
              <Type var="Label" sx={{ fs: "14px" }}>
                Chain ID
              </Type>
              <Type sx={{ fs: "14px" }}>{usdt?.chainID}</Type>
            </Flex>

            <Flex jc="space-between" sx={{ mt: "20px" }}>
              <Type var="Label" sx={{ fs: "14px" }}>
                Company
              </Type>
              <Type sx={{ fs: "14px" }}>{company?.companyName}</Type>
            </Flex>
            <Flex jc="space-between" sx={{ mt: "20px" }}>
              <Type var="Label" sx={{ fs: "14px" }}>
                Status
              </Type>
              <Type sx={{ fs: "14px" }}>
                {usdt?.active ? <>Active</> : <>No Active</>}
              </Type>
            </Flex>
          </Box>
        </Card>
        <Card>
          <Flex jc="space-between" sx={{ mt: "20px" }}>
            <Type var="Label" sx={{ fs: "14px" }}>
              Target Wallet
            </Type>
            <Type sx={{ fs: "14px" }}>{usdt?.targetWallet}</Type>
          </Flex>
          <Flex jc="space-between" sx={{ mt: "20px" }}>
            <Type var="Label" sx={{ fs: "14px" }}>
              Address
            </Type>
            <Type sx={{ fs: "14px" }}>{usdt?.address}</Type>
          </Flex>
        </Card>
      </Grid>{" "}
    </>
  );
};

export default USDTDetail;
