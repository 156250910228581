import { useCallback } from "react";
import { apiUrl as baseUrl } from "../config";
import { useAuth } from "./auth";

var refreshing = false;

export const useRequest = () => {
  const auth = useAuth();

  return useCallback(
    (...params) => {
      return new Promise(async (resolve, reject) => {
        try {
          if (refreshing) await new Promise((r) => setTimeout(r, 2000));
          if (params[3]) await checkToken(auth);

          const res = await request(...params);

          if (res.status === 401) {
            if (auth) {
              auth.signout();
              window.location.replace(
                `/login?from=${window.location.pathname}`
              );
            }
            reject({ status: res.status, errors: "Unauthorised Access" });
          } else if (res.status === 403) {
            window.location.replace("/no-access");
            reject({ status: res.status, errors: "Access Denied" });
          } else if (!res.ok) {
            const eData = await res.json();
            reject({ status: res.status, errors: eData });
          }
          const resData = await (res.status !== 204 ? res.json() : {});
          resolve(resData);
        } catch (error) {
          console.log("console error", error);
          reject(error);
        }
      });
    },
    [auth]
  );
};

export default function request(
  url,
  data = null,
  options = {},
  withAuth = false
) {
  const defaultOptions = {
    method: data ? "POST" : "GET",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    redirect: "follow",
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin",
    ...options,
  };
  if (data)
    if (defaultOptions.headers["Content-Type"] === "application/json")
      defaultOptions.body = JSON.stringify(data);
    else defaultOptions.body = data;
  if (withAuth)
    defaultOptions.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.tokens?.accessToken
    }`;
  return fetch(`${baseUrl}/${url}`, defaultOptions);
}

function checkToken(auth) {
  return new Promise(async (resolve, reject) => {
    const tokens = JSON.parse(localStorage.getItem("user"))?.tokens;
    if (!tokens || tokens === "undefined") resolve();

    const jwt = JSON.parse(atob(tokens?.accessToken?.split(".")[1]));

    let milliExp = jwt.exp * 1000;

    // function toTimestamp(strDate) {
    //   var datum = Date.parse(strDate);
    //   return datum / 1000;
    // }
    // let expireDate = toTimestamp(tokens.expiration);

    // earlier a couple of min before expiry
    // milliExp -= 1 * 60 * 1000;

    // expireDate -= 3 * 60;
    if (milliExp < Date.now()) {
      refreshing = true;
      try {
        await refreshToken(tokens, auth);
        refreshing = false;
        resolve();
      } catch (error) {
        reject(error);
      }
    }
    resolve();
  });
}

async function refreshToken(tokens, auth) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request(`auth/refreshToken/`, {
        refreshToken: tokens.refreshToken,
      });
      // console.log("request send");
      const { data } = await res.json();
      // console.log("data data", data);
      if (data?.accessToken) auth.saveTokens(data);
      // localStorage.setItem(
      //   "user",
      //   JSON.stringify({
      //     tokens: data,
      //     ...JSON.parse(localStorage.getItem("user")),
      //   })
      // );
      resolve(data);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
