import { Box, Flex } from "../../ui";
import { useDashboardStatistics } from "./Provider";
import { useEffect } from "react";
import GradientCard from "./Styled/GradientCard";
import Wallet from "./Wallet";
import NetworkChart from "./NetworkChart";

const Statistics = () => {
  const {
    state: { selected: statistics },
    fetchList: fetchStatistics,
  } = useDashboardStatistics();
  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);
  return (
    <Box>
      <Flex jc="space-between" sx={{ mt: "10px" }}>
        <GradientCard
          background="transparent linear-gradient(180deg, #F48665 0%, #F48665 1%, #FDA23F 100%) 0% 0% no-repeat padding-box"
          count={statistics?.cards?.companyCount}
          title="Companies"
          IconName="Company"
          IconW="50"
        />
        <GradientCard
          background="transparent linear-gradient(180deg, #E36CD9 0%, #FE60AE 100%) 0% 0% no-repeat padding-box"
          count={statistics?.cards?.eventCount}
          title="Events"
          IconName="Calendar"
          IconW="50"
        />
        <GradientCard
          background="transparent linear-gradient(180deg, #23BDB8 0%, #43E794 100%) 0% 0% no-repeat padding-box"
          count={statistics?.cards?.userCount}
          title="Users"
          IconName="User"
          IconW="35"
        />
        <GradientCard
          background="transparent linear-gradient(180deg, #9A56FF 0%, #D78AFD 100%) 0% 0% no-repeat padding-box"
          count={statistics?.cards?.clientCount}
          title="Clients"
          IconName="Clients"
          IconW="40"
        />
      </Flex>
      <Box sx={{ mt: "20px" }}>
        <NetworkChart liquidity={statistics?.liquidity} />
      </Box>
    </Box>
  );
};

export default Statistics;
