const fields = [
  {
    name: "email",
    label: "Email",
    focus: true,
  },
  {
    name: "password",
    label: "Password",
    type: "password",
  },
  {
    name: "mobileNumber",
    label: "Phone",
  },
];

export default fields;
