import React, { useCallback, useState } from "react";
import { Box, Button, Select, Type } from "../../ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useCompany } from "./Provider";
import { useUtils } from "../../services/utilsProvider";

const schema = Yup.object().shape({
  newStatus: Yup.number(),
});

const UpdateStatus = ({ setShow, id, handleSuccess, reload, setReload }) => {
  const { notify } = useUtils();

  const mapDataStatus = { label: "name", value: "id" };
  const [status, setStatus] = useState([
    { id: 0, name: "DRAFT" },
    { id: 1, name: "COMING SOON" },
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onChangeStatus = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );
  // const handleSuccess = () => {
  //   notify("Status updated successfully" + "success");
  //   setShow(false);
  // };
  const { UpdateStatus } = useCompany();

  const onSubmit = async (values) => {
    try {
      await UpdateStatus(id, values);
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      console.error("e", e.message);
    }
  };
  return (
    <Box sx={{ p: "20px" }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Type as="h1" var="Title" sx={{ mb: 25 }}>
          Update Status
        </Type>
        <Select
          onChange={onChangeStatus}
          mapData={mapDataStatus}
          data={status}
          error={!!errors.newStatus}
          inputStyle={{ width: "200px", marginBottom: "20px" }}
          size="medium"
          name="Select Status"
          inputProps={{
            ...register("newStatus"),
          }}
        />
        <Button
          bg="secondary"
          type="submit"
          loading={isSubmitting}
          sx={{ ml: "30px" }}
        >
          Update Status
        </Button>
      </form>
    </Box>
  );
};

export default UpdateStatus;
