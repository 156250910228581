import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, InputText, Link, Type } from "../../ui";
import "../../css/flags.css";
import { useAuth } from "../../services/auth";

const schema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("Required")
    .min(4, "Too Short, a password with at least 4 letters is required!"),
});

function LoginForm({ onSuccess }) {
  const [error, setError] = useState(null);
  const { signin } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleError = ({ status, errors: e }) => {
    if (status === 400) {
      if (e.message === "INVALID_USER" || e.message === "INVALID_LOGIN")
        setError(
          <Type sx={{ c: "red", ta: "center", mt: -20, mb: 60 }}>
            Invalid Login Credentials.
          </Type>
        );
    } else if (status === 429) {
      setError(
        <Type sx={{ c: "red", ta: "center", mt: -20, mb: 60 }}>
          Too many request. {e.data.message}
        </Type>
      );
    }
  };

  const onSubmit = async (values) => {
    setError(null);
    try {
      await signin(values);
      reset();
      onSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      handleError(e);
      console.log("e", e);
    }
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ w: "70%", mxw: 500 }}
    >
      {error && error}
      <InputText
        label="Email"
        error={!!errors.email}
        msg={errors.email?.message}
        inputProps={{
          ...register("email"),
          autoFocus: true,
        }}
      />
      <InputText
        label="Password"
        type="password"
        error={!!errors.password}
        msg={errors.password?.message}
        inputProps={{
          ...register("password"),
        }}
      />
      <Box sx={{ mb: 20 }}>
        {/* <Checkbox label="Remember me" /> */}
        <Type sx={{ ta: "right" }}>
          <Link to="/forgot-password?type=email">Forgot Password?</Link>
        </Type>
      </Box>
      <Button
        type="submit"
        loading={isSubmitting}
        size="large"
        bg="secondary"
        sx={{ mx: "auto", w: "100%" }}
      >
        Log In
      </Button>
      {/* <Type sx={{ ta: "center", mt: 20 }}>
        Don’t have an account? <Link to="/register">Register</Link> here
      </Type> */}
    </Box>
  );
}

export default LoginForm;
