import React, { useCallback, useEffect, useState } from "react";
import fields from "./fields";
import { Box, Button, Checkbox, InputText, Select } from "../../ui";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNetworks } from "../Networks/Provider";
import Label from "../../ui/Label";
import { useUSDT } from "./Provider";
import ErrorsList from "../../ui/ErrorsList";
import { useCompany } from "../Companies/Provider";

const schema = Yup.object().shape({
  chainID: Yup.number().typeError("must be number"),
  companyID: Yup.number().required("required"),
  targetWallet: Yup.string().required("required field"),
  address: Yup.string().required("required field"),
  active: Yup.bool(),
});

const USDTForm = ({ handleSuccess, USDT, ID }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    state: { list: companies },
    fetchList: getCompanies,
  } = useCompany();
  const {
    state: { list: networks },
    fetchList: getNetwork,
  } = useNetworks();
  const [Error, setError] = useState(null);
  const mapDataCompany = { label: "companyName", value: "id" };
  const mapDataNetwork = { label: "networkName", value: "chainID" };
  // const handleCheck = (active) => {
  //   if (active === 1) return true;
  //   else return false;
  // };
  const onChangeCompany = useCallback(
    (v, name) => {
      setValue(name, v.value);
    },
    [setValue]
  );

  const { create } = useUSDT();
  // Use Effects
  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    getNetwork();
  }, [getNetwork]);

  // Getters
  const getData = (name) => {
    switch (name) {
      case "networks":
        return networks;
      case "companies":
        return companies;
    }
  };

  const getMapData = (mapData) => {
    switch (mapData) {
      case "mapDataNetwork":
        return mapDataNetwork;
      case "mapDataCompany":
        return mapDataCompany;
    }
  };

  const onSubmit = async (values) => {
    values.active ? (values.active = 1) : (values.active = 0);

    try {
      await create(values);
      handleSuccess();
    } catch (error) {
      setError({ title: error.errors.message });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {Error && (
        <Box sx={{ mb: "40px" }}>
          <ErrorsList error={Error} />
        </Box>
      )}
      {fields.map((field, index) => (
        <>
          {field.select && (
            <>
              <Label>{field.label}*</Label>
              <Select
                error={!!errors[field?.name]}
                onChange={onChangeCompany}
                data={getData(field?.data)}
                mapData={getMapData(field?.mapData)}
                inputStyle={{ width: "390px", marginBottom: "40px" }}
                size="medium"
                inputProps={{
                  ...register(field?.name),
                }}
                name={`Select ${field?.label}`}
              />
            </>
          )}
          {!field.select && !field.switch && (
            <InputText
              label={field?.required ? field?.label + " *" : field?.label}
              name={field?.name}
              type={field?.type}
              data-testid={field?.name}
              key={index}
              error={!!errors[field?.name]}
              msg={errors[field?.name]?.message}
              style={{
                width: "100%",
                marginBottom: "40px",
                display: field.disable ? "none" : "",
              }}
              inputProps={{
                defaultValue: USDT?.[field?.name],
                ...register(field?.name),
                "aria-label": field?.name,
              }}
            />
          )}
          {field.switch && (
            <Checkbox
              id="Active"
              inputProps={{
                ...register(field?.name),
              }}
              checked={USDT?.["active"]}
              label="Active"
              size="small"
            />
          )}
        </>
      ))}
      <Button
        type="submit"
        bg="secondary"
        name="create"
        data-testid="create"
        sx={{ fs: "15px", w: 400, br: "8px", mt: "40px" }}
      >
        {USDT ? <>Update</> : <>Add</>} USDT
      </Button>
    </form>
  );
};

export default USDTForm;
