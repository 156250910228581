import React from "react";
import { Box, Type } from "../../../ui";

const CountryRender = (row) => {
  return (
    <Box>
      <Type var="tableTitle">
        {row?.country ? row?.country?.country : "not specific"}
      </Type>
    </Box>
  );
};

export default CountryRender;
