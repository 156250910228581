import { lazy, Suspense } from "react";
import styled, { css } from "styled-components";
import { firstUp } from "../utilities/functions";

const icons = {
  Search: lazy(() => import("./icons/Search")),
  ArrowRight: lazy(() => import("./icons/ArrowRight")),
  ArrowLeft: lazy(() => import("./icons/ArrowLeft")),
  Bell: lazy(() => import("./icons/Bell")),
  Snapchat: lazy(() => import("./icons/Snapchat")),
  Facebook: lazy(() => import("./icons/Facebook")),
  Instagram: lazy(() => import("./icons/Instagram")),
  Linkedin: lazy(() => import("./icons/Linkedin")),
  Twitter: lazy(() => import("./icons/Twitter")),
  Tiktok: lazy(() => import("./icons/Tiktok")),
  Pen: lazy(() => import("./icons/Pen")),
  CheckCircle: lazy(() => import("./icons/CheckCircle")),
  Cloud: lazy(() => import("./icons/Cloud")),
  Donut: lazy(() => import("./icons/Donut")),
  Pdf: lazy(() => import("./icons/Pdf")),
  CircleArrowRight: lazy(() => import("./icons/CircleArrowRight")),
  CircleArrowLeft: lazy(() => import("./icons/CircleArrowLeft")),
  EllipsisVertical: lazy(() => import("./icons/EllipsisVertical")),
  Sliders: lazy(() => import("./icons/Sliders")),
  CheckCircleSolid: lazy(() => import("./icons/CheckCircleSolid")),
  AngleLeft: lazy(() => import("./icons/AngleLeft")),
  AngleDown: lazy(() => import("./icons/AngleDown")),
  AngleUp: lazy(() => import("./icons/AngleUp")),
  Xmark: lazy(() => import("./icons/Xmark")),
  Mouse: lazy(() => import("./icons/Mouse")),
  Play: lazy(() => import("./icons/Play")),
  Download: lazy(() => import("./icons/Download")),
  Copy: lazy(() => import("./icons/Copy")),
  Gears: lazy(() => import("./icons/Gears")),
  Shield: lazy(() => import("./icons/Shield")),
  Globe: lazy(() => import("./icons/Globe")),
  ClipboardList: lazy(() => import("./icons/ClipboardList")),
  LightBulb: lazy(() => import("./icons/LightBulb")),
  UniversalAccess: lazy(() => import("./icons/UniversalAccess")),
  CloudSun: lazy(() => import("./icons/CloudSun")),
  Seedling: lazy(() => import("./icons/Seedling")),
  PeopleGroup: lazy(() => import("./icons/PeopleGroup")),
  TableColumns: lazy(() => import("./icons/TableColumns")),
  LayerGroup: lazy(() => import("./icons/LayerGroup")),
  Building: lazy(() => import("./icons/Building")),
  User: lazy(() => import("./icons/User")),
  Category: lazy(() => import("./icons/Categories")),
  Company: lazy(() => import("./icons/companies")),
  Country: lazy(() => import("./icons/countries")),
  Detail: lazy(() => import("./icons/Detail")),
  DashboardIcon: lazy(() => import("./icons/DashboardIcon")),
  Clients: lazy(() => import("./icons/Clients")),
  ChechChecked: lazy(() => import("./icons/Checkbox - Checked")),
  CheckClosed: lazy(() => import("./icons/Checkbox - Closed")),
  ListIcon: lazy(() => import("./icons/ListIcon")),
  Edit: lazy(() => import("./icons/Edit")),
  Delete: lazy(() => import("./icons/Delete")),
  Trash: lazy(() => import("./icons/Trash")),
  Filter: lazy(() => import("./icons/Filter")),
  Copied: lazy(() => import("./icons/Copied")),
  Plus: lazy(() => import("./icons/Plus")),
  EllipsisHorizontal: lazy(() => import("./icons/EllipsisHorizontal")),
  Update: lazy(() => import("./icons/Update")),
  Networks: lazy(() => import("./icons/Networks")),
  Dollar: lazy(() => import("./icons/Dollar")),
  AddButton: lazy(() => import("./icons/Button - Add - Normal")),
  Calendar: lazy(() => import("./icons/Calendar")),
  PlusSquare: lazy(() => import("./icons/PlusSquare")),
};

const Wrap = styled.span`
  line-height: 0;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
      width: calc(18px + ${(props) => props.w}px);
      height: calc(18px + ${(props) => props.w}px);
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:hover {
        fill: ${props.theme.color[props.hover]};
        background-color: ${(props) =>
          props.hoverBgc ? props.hoverBgc : props.theme.color.white};
        opacity: ${(props) => props.hoverOpacity && props.hoverOpacity};
      }
      &:active {
        background-color: ${props.theme.color.gray2k};
        transform: scale(0.99);
      }
    `}
  svg {
    fill: ${(props) => props.c && props.theme.color[props.c]};
    width: ${(props) => props.w}px;
  }
  ${(props) =>
    props.circle &&
    css`
      stroke-width: ${props.w + 22}px;
      display: inline-block;
      background-color: ${(props) => props.c && props.theme.color[props.c]};
      position: relative;
      svg {
        fill: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

function Icon({ name, w = "30", c = "dark", hover, ...rest }) {
  if (!icons[firstUp(name || "")]) return;
  const Component = icons[firstUp(name)];
  return (
    <Suspense fallback={"loading"}>
      <Wrap {...{ w, c, hover, ...rest }}>
        <Component />
      </Wrap>
    </Suspense>
  );
}

export default Icon;
