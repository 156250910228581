import React, { useState } from "react";
import {
  Box,
  Flex,
  Icon,
  InputText,
  Pagination,
  TableContainer,
  Type,
} from "../../ui";
import Label from "../../ui/Label";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import IconButton from "../../ui/IconButton";
import { useDashboardStatistics } from "./Provider";
import { toUtc } from "../../utilities/functions";

const Grid = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 42px;
`;

const schema = Yup.object().shape({
  startDate: Yup.date().required().typeError("Required date"),
  endDate: Yup.date().required().typeError("Required date"),
});

const TransferLog = () => {
  const {
    state: { logs },
    fetchLogs,
  } = useDashboardStatistics();

  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,

    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    values.startDate = toUtc(values.startDate, false);
    values.endDate = toUtc(values.endDate, false);
    try {
      await fetchLogs(values);
      setShow(true);
    } catch (e) {
      window.scrollTo(0, 0);
      console.log("e", e);
    }
  };
  const cols = [
    { label: "ID", name: "id" },
    { label: "USDT Amount", name: "USDTamount" },
    { label: "Token Count", name: "tokenCount" },
    { label: "Creation Date", name: "creationDate" },
    { label: "Modification Date", name: "modificationDate" },
  ];

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Type as="h1" var="Heading" sx={{ mb: 20 }}>
        Transfer Log
      </Type>

      <Grid>
        <Label>From</Label>
        <Label>To</Label>
      </Grid>
      <Grid>
        <InputText
          inputProps={{
            ...register("startDate"),
            autoFocus: true,
          }}
          error={!!errors?.startDate}
          msg={errors["startDate"]?.message}
          type="date"
        />
        <InputText
          inputProps={{
            ...register("endDate"),
            autoFocus: true,
          }}
          error={!!errors?.endDate}
          msg={errors["endDate"]?.message}
          type="date"
        />{" "}
        <IconButton
          loading={isSubmitting}
          type="submit"
          className="small"
          bg="secondary"
        >
          <Icon name="filter" />
        </IconButton>
      </Grid>
      {show && (
        <>
          <Box>
            <TableContainer
              empty={logs?.length === 0 ? true : false}
              data={logs}
              cols={cols}
            />
          </Box>
          <Flex jc="end" ai="flex-top" sx={{ mb: 60 }}>
            <Box>
              <Pagination
                count={10}
                page={1}
                onPageChange={() => console.log("page")}
              />
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default TransferLog;
